import
{
    Capacitor
} from '@capacitor/core';

import
{
    Geolocation,
    Position,

} from '@capacitor/geolocation';

import
{
    useEffect,
    useState
} from 'react';

import
{
    Polygon
} from "geojson";

import inside from 'point-in-polygon';
import EventEmitter from 'events';

type Cache = {
    isWatching: boolean,
    position: Position | null,
    observer: EventEmitter;
};

const cache: Cache = {
    isWatching: false,
    position: null,
    observer: new EventEmitter()
};

const watchLocation = async () =>
{
    if (!cache.isWatching) {
        cache.isWatching = true;

        Geolocation.watchPosition({
            "enableHighAccuracy": true
        }, (position) =>
        {
            if (!cache.position || !(
                cache.position.coords.latitude === position?.coords.latitude &&
                cache.position.coords.longitude === position?.coords.longitude
            )) {
                cache.position = position;
                cache.observer.emit("position:changed", position);
            }
        });
    }
};

export const locationInArea = (location: Position, area: Polygon) =>
{
    const point = [location.coords.longitude, location.coords.latitude];
    const polygon = area.coordinates.slice().pop() as number[][];

    return inside(point, polygon);

};

export const getGeoLocation = async () =>
{
    return await Geolocation.getCurrentPosition();
};

export const useGeoLocation = (refresh: number = 0) =>
{
    const [position, setPosition] = useState<Position | null>(cache.position);

    watchLocation();

    useEffect(() =>
    {
        cache.observer.on("position:changed", setPosition);
        return () =>
        {
            cache.observer.off("position:changed", setPosition);
        };
    }, []);

    return position;
};