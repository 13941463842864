import { useL10n } from "@ews/react-localization-context";
import
{
    appsOutline,
    options, peopleOutline, personAddOutline, personOutline, statsChartOutline, trashOutline
} from "ionicons/icons";

import { FC } from "react";
import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import
{
    SystemStatusIcon
} from "../../Icons";

import type {
    MenuToggleProps
} from "../types";

import
{
    useMyPermissions,
    useMyProfile
} from "../../Login/LoginProvider";

import
{
    User,
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";

import NewCustomerModal from "../../Customer/NewCustomer/NewCustomerModal";
import NewSystemModal from "../../System/NewSystem/NewSystemModal";
import { ReactClickEvent } from "../../types";
import NewUserModal from "../../User/NewUser/NewUserModal";
import { useDialog } from "../../Dialog";
import { useCustomer, useRemoveCustomer } from "../../ReactData/customer";

type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const uuidToLTree = (uuid: string) => uuid.replace(/-/g, '_');

const Customer: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate, params, router } = useNamedRoutes();
    const { id: customerId } = routeParams;
    const customer = useCustomer(customerId || "");
    const permissions: UserPermissions = useMyPermissions();
    const me: User = useMyProfile();
    const dialog = useDialog();
    const removeCustomer = useRemoveCustomer();

    title = title || t("Customersettings");

    const mayDelete = !(me.tree || "").split(".").includes(uuidToLTree(customerId || params().id || "")) && !customer.children;

    const handleDelete = async (e: ReactClickEvent) =>
    {
        if (!customerId) return;

        const response = await dialog({
            title: t("Remove customer"),
            message: t("Are ou sure, you want to completely remove the customer from REACT?"),
            confirmButtonTitle: t("Ok"),
            cancelButtonTitle: t("Cancel")
        });

        if (response) {
            await removeCustomer(customerId);
            onMenuToggle && onMenuToggle(e);
            const redirect = generate('customers');
            router?.push(redirect);
        }

    };

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {permissionAllowed(permissions.createCustomer!) ? <MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('customer', routeParams)}
                    icon={options}
                /> : <></>}

                {(permissionAllowed(permissions.createCustomer!) && mayDelete) ? <MenuLink
                    title={t('[SID_MNU_HK_DELETE]')}
                    onClick={handleDelete}
                    icon={trashOutline}
                /> : <></>}
                {permissionAllowed(permissions.createCustomer!) ? <>
                    {/* <MenuLink
                    title={t('Create Customer')}
                    onClick={onMenuToggle}
                    link={generate('customer:addcustomer', routeParams)}
                    icon={peopleOutline}
                /> */}
                    <MenuLink
                        title={t('Create Customer')}

                        id="newCustomerFromCustomer"
                        icon={peopleOutline}
                    />
                    <NewCustomerModal
                        parentId={customerId}
                        onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                        trigger={"newCustomerFromCustomer"} />
                    <MenuSpacer /></> : <></>}
                <MenuLink
                    title={t('Darunterliegende Kunden')}
                    onClick={onMenuToggle}
                    link={generate('customer:customers', routeParams)}
                    icon={options}
                />
                <MenuSpacer />
                {permissionAllowed(permissions.createUser!) ?
                    // <MenuLink
                    //     title={t('Create User')}
                    //     onClick={onMenuToggle}
                    //     link={generate('customer:adduser', routeParams)}
                    //     icon={personAddOutline}
                    // />
                    <>
                        <MenuLink
                            title={t('Create User')}
                            id="newUserFromCustomer"
                            icon={personAddOutline}
                        />
                        <NewUserModal
                            customerId={customerId}
                            onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                            trigger={"newUserFromCustomer"} /></>
                    : <></>}
                <MenuLink
                    title={t('List users')}
                    onClick={onMenuToggle}
                    link={generate('customer:users', routeParams)}
                    icon={personOutline}
                />
                <MenuSpacer />
                {permissionAllowed(permissions.grantSystemAccess!) ?
                    // <MenuLink
                    //     title={t('Add system')}
                    //     onClick={onMenuToggle}
                    //     icon={<SystemStatusIcon height={24} width={24} />}
                    // />
                    <>
                        <MenuLink
                            title={t('Add system')}
                            id="newSystemFromCustomer"
                            icon={<SystemStatusIcon height={24} width={24} />}
                        />
                        <NewSystemModal
                            customerId={customerId}
                            onDismiss={(e) => onMenuToggle?.(e as unknown as ReactClickEvent)}
                            trigger={"newSystemFromCustomer"} /></>

                    : <></>}
                <MenuLink
                    title={t('List systems')}
                    onClick={onMenuToggle}
                    link={generate('customer:systems', routeParams)}
                    icon={appsOutline}
                />
                <MenuSpacer />
                <MenuLink
                    title={t('Event storage')}
                    link={generate('customer:events', routeParams)}
                    onClick={onMenuToggle}
                    icon={statsChartOutline}
                />
            </SidebarSection>
        </Sidebar>
    );

};

export default Customer;
