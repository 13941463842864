import
{
    FC
} from "react";

import TypeAhead from "../TypeAhead/TypeAheadString";

import
{
    Customer,
    PaginatedResult,
    treeContains
} from "@ews/react-data";

import
{
    getCustomerDisplayName,
    useCustomer,
    useTypeAheadCustomerList
} from "../ReactData/customer";

import
{
    useMyProfile
} from "../Login/LoginProvider";
import { useL10n } from "@ews/react-localization-context";

type LabelledCustomer = Customer & {
    label: string;
};

const CustomerTypeAhead: FC<{
    disabled?: boolean;
    label?: string;
    currentId?: string;
    skipTrees?: string[],
    onChange: (id: string | null) => void;
}> = ({
    label = "Customer",
    currentId = "",
    disabled,
    skipTrees = [],
    onChange
}) =>
    {
        const typeAhead = useTypeAheadCustomerList();
        const customer = useCustomer(currentId);
        disabled = disabled || ((customer.id || "") !== currentId);
        const me = useMyProfile();

        return <TypeAhead
            id="customer-type-ahead"
            text={label}
            disabled={disabled}
            debounce={300}
            value={customer.id ? getCustomerDisplayName(customer) : " --- "}
            onFilter={async (data) => new Promise(async resolve =>
            {
                const search = await typeAhead({ pageSize: 10, name: data, customerNumber: data, searchOperator: "OR", sortCriteria: "name" }) as PaginatedResult<Customer>;

                if (!me.customerId) {
                    search.results.unshift({
                        name: " --- ",
                        tree: ""
                    } as Customer);
                }

                resolve(search
                    .results
                    .filter((c: Customer) =>
                    {
                        return !skipTrees?.some((tree) => treeContains(tree, c.tree!));
                    })
                    .map((data: Customer) =>
                    {
                        (data as LabelledCustomer).label = getCustomerDisplayName(data as Customer);
                        return { key: "label", data, preventAutoSelect: !Boolean(data.id) };
                    })
                );
            })}
            onChange={(data) =>
            {
                const id = (data as Customer).id;
                onChange(id || null);
            }}
        />;

    };

export default CustomerTypeAhead;