import { permissionAllowed, permissionAvailable, SystemLicense } from "@ews/react-data";
import { useEffect, useState } from "react";

import
{
    useModifySystemLicense,
    useSystem,
    useSystemLicense
} from "../../../ReactData/system";

import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import SettingsTitle from "../../../Settings/SettingsTitle/SettingsTitle";
import { LicenseSettingsProps } from "./types";
import { useMyProfileId } from "../../../Login/LoginProvider";
import { useUserPermissions } from "../../../ReactData/user";

const LicenseSettings: React.FC<LicenseSettingsProps> = ({ systemId }) =>
{

    const system = useSystem(systemId);
    const license = useSystemLicense(systemId);
    const modifySystemLicense = useModifySystemLicense();

    const me = useMyProfileId();
    const userPermission = useUserPermissions(me);

    function saveSystemLicense(key: keyof SystemLicense, value: any)
    {
        if (!systemId || !(key in license)) return;


        license[key] = value;

        modifySystemLicense(systemId, { [key]: value } as SystemLicense);
    }
    const [systemLicenseState, setSystemLicenseState] = useState<SystemLicense>({} as SystemLicense);


    useEffect(() =>
    {
        if (!systemLicenseState.systemId && license.systemId) {
            setSystemLicenseState(license);
        }
    }, [systemId, license]);

    return <>
        <SettingsCol >

            <SettingsSelect
                disabled={!permissionAllowed(userPermission.isAdmin!)}
                text={"Licences"}
                multiple={true}
                value={""}
                onBlur={({ value }) => { console.log(value); }}>
                <SettingsSelectOption text={"Detailed view"} value={"detailedView"} />
                <SettingsSelectOption text={"Operation and push"} value={"operationAndPush"} />
                <SettingsSelectOption text={"Plan view"} value={"planView"} />
            </SettingsSelect>

            <SettingsTextInput
                disabled
                lines="none"
                text={"End of licence"}
                //inputType={"date"}
                value={new Date(2022, 5, 10).toLocaleString()}
                onBlur={({ value }) => 
                {
                    console.log(new Date(value));
                }} />
            <SettingsTextInput
                disabled
                lines="none"
                text={"Notification to"}
                inputType={"email"}
                value={"email"}
                onBlur={({ value }) =>
                {
                    console.log(value);
                }} />


        </SettingsCol>
        <SettingsCol >
            <SettingsTitle
                text={"Paying client"}
                endText={':'} />

            <SettingsLabel
                text=""
                value={system.name || ""} />
            <SettingsCheckbox
                text={"Common collective invoice"}
                checked={license.collectiveInvoiceForCustomer || false}
                onChange={(collectiveInvoiceForCustomer) =>
                {
                    setSystemLicenseState({ ...systemLicenseState, collectiveInvoiceForCustomer } as SystemLicense);
                    saveSystemLicense("collectiveInvoiceForCustomer", collectiveInvoiceForCustomer);
                }} />

            <SettingsCheckbox
                text={"Common collective invoice of licences"}
                checked={license.collectiveInvoiceOfLicenses || false}
                onChange={(collectiveInvoiceOfLicenses) =>
                {
                    setSystemLicenseState({ ...systemLicenseState, collectiveInvoiceOfLicenses } as SystemLicense);
                    saveSystemLicense("collectiveInvoiceOfLicenses", collectiveInvoiceOfLicenses);
                }} />

            <SettingsSelect
                text={"Log Level"}
                multiple={true}
                value={license.licenceType?.toString() || "0"}
                onBlur={({ value }) =>
                {
                    if (license.licenceType?.toString() === value) return;
                    const licenceType = Number(value);
                    setSystemLicenseState({ ...systemLicenseState, licenceType } as SystemLicense);
                    saveSystemLicense("licenceType", licenceType);
                }}>
                <SettingsSelectOption text={"Error"} value={"0"} />
                <SettingsSelectOption text={"Info"} value={"1"} />
                <SettingsSelectOption text={"Warning"} value={"2"} />
                <SettingsSelectOption text={"Debug"} value={"3"} />
            </SettingsSelect>
        </SettingsCol>
        <SettingsCol >
            <></>
        </SettingsCol>
    </>;
};

export default LicenseSettings;