import { useL10n } from "@ews/react-localization-context";
import
{
    options, powerOutline, trashOutline
} from "ionicons/icons";

import { FC } from "react";
import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import type {
    MenuToggleProps
} from "../types";


import
{
    useMyPermissions,
    useMyProfile
} from "../../Login/LoginProvider";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";

import
{
    useDialog
} from "../../Dialog";

import
{
    useRemoveUser
} from "../../ReactData/user";

import
{
    ReactClickEvent
} from "../../types";

type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const User: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate, router } = useNamedRoutes();
    const { id: userId } = routeParams;
    const me = useMyProfile();
    const dialog = useDialog();
    const permissions: UserPermissions = useMyPermissions();
    const removeUser = useRemoveUser();

    title = title || t('Usersettings');

    const mayDelete = userId !== me.id;

    const handleDelete = async (e: ReactClickEvent) =>
    {
        if (!userId) return;

        const response = await dialog({
            title: t("Remove user"),
            message: t("Are ou sure, you want to completely remove the user from REACT?"),
            confirmButtonTitle: t("Ok"),
            cancelButtonTitle: t("Cancel")
        });

        if (response) {
            await removeUser(userId);
            onMenuToggle && onMenuToggle(e);
            const redirect = generate('users');
            router?.push(redirect);
        }

    };

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                {/* <AcquireAuthorization onMenuToggle={onMenuToggle} /> */}
                {permissionAllowed(permissions.createUser!) ? <MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('user', routeParams)}
                    icon={options}
                /> : <></>}

                <MenuLink
                    title={t('Simulate Login')}
                    onClick={onMenuToggle}
                    icon={powerOutline}
                />

                {(permissionAllowed(permissions.createUser!) && mayDelete) ? <>
                    <MenuSpacer />
                    <MenuLink
                        title={t('[SID_MNU_HK_DELETE]')}
                        onClick={handleDelete}
                        icon={trashOutline}
                    /></> : <></>}
            </SidebarSection>
        </Sidebar>
    );

};

export default User;