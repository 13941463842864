import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonIcon, IonInput, IonItem, IonList, IonListHeader, IonText } from "@ionic/react";
import { search } from "ionicons/icons";
import React, { useRef, useState } from "react";
import { SystemElement } from "../../../ReactData/system";
import { deviceList } from "../../deviceList";
import style from "./ElementList.module.scss";
import { Props } from "./types";
import { getDeviceIconsFilePath } from "../Element/Element";
import { fileIconSize } from "../../Plan";
import { formatLogicalAddress, NoDeviceNumber } from "@ews/zlt-events";


const ElementList: React.FC<Props> = ({
    iconData,
    onGroup,
    onElement,
    onChange,
    groups,
    currentElement,
}) =>
{

    const { translate: t } = useL10n();

    const [searchElementValue, setSearchElementValue] = useState("");
    const [searchIconValue, setSearchIconValue] = useState("");
    const searchElementRef = useRef<HTMLIonInputElement>(null);
    const searchIconRef = useRef<HTMLIonInputElement>(null);


    function isHighlighted(groupId: number, id: number, numberGroup: number)
    {
        if (!currentElement) return false;

        return groupId === currentElement.groupId! &&
            id === currentElement.id! &&
            numberGroup === currentElement.numberGroup;
    }

    return <IonCard className={style.container}>
        <IonCardHeader color={"primary"}>
            <div className={style.header}>

                <div className={style.headItem}>
                    <div>
                        <IonText>
                            {
                                currentElement ?
                                    `${formatLogicalAddress(currentElement?.groupId!, currentElement.id || NoDeviceNumber, 1)} ${currentElement.text}`
                                    :
                                    ""
                            }
                        </IonText>
                    </div>
                </div>

                <div className={`${style.headItem}`}>
                    <div className={style.currentDevice}>
                        {iconData ? <img
                            className={style.img}
                            src={getDeviceIconsFilePath(`${fileIconSize}${iconData.img}_0`)}
                            alt=""
                            loading="lazy"
                            onError={(e) =>
                            {
                                const target = e.target as HTMLImageElement;
                                target.src = getDeviceIconsFilePath("question");
                            }}
                        /> : null}
                        <IonText>{iconData ? iconData.name.trim() : ""}</IonText>
                    </div>
                </div>
            </div>

        </IonCardHeader>
        <IonCardContent>
            <div className={style.contentContainer}>

                {onGroup ? <div className={style.content}>
                    <IonItem
                        lines="full"
                    >
                        <div className={style.searchHeader}>
                            <IonText>{t("Element")}</IonText>
                            <div
                                className={style.search}
                            >
                                <IonInput
                                    label=""
                                    type="search"
                                    ref={searchElementRef}
                                    debounce={900}
                                    onIonChange={({ detail }) => setSearchElementValue(detail.value || "")} />
                                <IonButton expand="full" color={"success"}>
                                    <IonIcon icon={search} onClick={() => { searchElementRef.current?.click(); }}></IonIcon>
                                </IonButton>
                            </div>
                        </div>
                    </IonItem>
                    {

                        <IonList>
                            {groups.map((group, gIndex) =>
                            {
                                const elements = group.elements?.
                                    filter((element: SystemElement) =>
                                        element.id?.toString().toLocaleLowerCase().includes(searchElementValue.toLocaleLowerCase()) ||
                                        element.groupId?.toString().toLocaleLowerCase().includes(searchElementValue.toLocaleLowerCase()) ||
                                        element.text?.toString().toLocaleLowerCase().includes(searchElementValue.toLocaleLowerCase())
                                    );
                                return elements && elements?.length ?
                                    <React.Fragment key={`group-${gIndex}`} >
                                        <IonListHeader
                                            key={group.id}
                                            color={"primary"}
                                        >{`${group.id} ${group.text}`}
                                        </IonListHeader>
                                        {onElement ? elements?.map((element: SystemElement, eIndex: number) =>
                                        {
                                            return <IonItem
                                                lines="full"
                                                color={
                                                    isHighlighted(element.groupId!, element.id!, element.numberGroup!)
                                                        ? "success" : undefined}
                                                key={eIndex}>
                                                <IonButton
                                                    color={"secondary"}
                                                    onClick={() =>
                                                    {
                                                        if (onGroup) onGroup(group);
                                                        if (onElement) onElement(element);
                                                    }}
                                                >
                                                    {`${formatLogicalAddress(group.id!, element.id || NoDeviceNumber, 1)} ${element.text}`}
                                                </IonButton>
                                            </IonItem>;
                                        }) : null}
                                    </React.Fragment>
                                    :
                                    <React.Fragment key={`$group-${gIndex}`} />;
                            })}
                        </IonList>
                    }</div> : null}
                <div className={style.content}>
                    <IonItem
                        lines="full"
                    >
                        <div className={style.searchHeader}>
                            <IonText>{t("Icon")}</IonText>
                            <div
                                className={style.search}
                            >
                                <IonInput
                                    label=""
                                    type="search"
                                    ref={searchIconRef}
                                    debounce={900}
                                    onIonChange={({ detail }) => setSearchIconValue(detail.value || "")} />
                                <IonButton expand="full" color={"success"}>
                                    <IonIcon icon={search} onClick={() => { searchIconRef.current?.click(); }}></IonIcon>
                                </IonButton>
                            </div>
                        </div>

                    </IonItem>
                    <IonList>
                        {Object.keys(deviceList)
                            .sort((a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()))
                            .filter((key) =>
                                key.toLocaleLowerCase().includes(searchIconValue.toLocaleLowerCase()))
                            .map((key) =>
                            {
                                const { name } = deviceList[key as keyof typeof deviceList];

                                return <IonItem
                                    lines="full"
                                    key={key}
                                    className={style.device}
                                    color={iconData && iconData.img === key ? "success" : undefined}
                                    onClick={() => { if (onChange) onChange({ img: key, name }); }}>
                                    <img
                                        slot="start"
                                        className={style.img}
                                        width={25}
                                        height={25}
                                        src={getDeviceIconsFilePath(`${fileIconSize}${key}_0`)}
                                        loading="lazy"
                                        alt=""
                                        onError={(e) =>
                                        {
                                            const target = e.target as HTMLImageElement;
                                            target.src = getDeviceIconsFilePath("question");
                                        }}
                                    />
                                    <p
                                        slot="end"
                                        className={style.text}
                                    >
                                        {name}
                                    </p>
                                </IonItem>;
                            })}
                    </IonList>
                </div>
            </div>

        </IonCardContent>
    </IonCard>;
};

export default ElementList;