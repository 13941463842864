import { CRS, LatLngBoundsExpression, Map as MapType } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import style from "./Map.module.scss";


import { useEffect, useRef, useState } from 'react';
import { ImageOverlay, MapContainer } from 'react-leaflet';
import Marker from '../Element/Element';
import { EventClick, EventZoom } from '../Event/Event';
import { Coords, MapProps } from './types';


import { AuthorizationLevel } from '@ews/zlt-events';
import { IonItem } from '@ionic/react';
import { useAuthorization } from '../../../Authorization';
import { SystemElement } from '../../../ReactData/system';


function getMinZoom(width: number, height: number, containerWidth: number = 1000, containerHeight: number = 1000)
{
    if (width > containerWidth || height > containerHeight)
        if (width > height) {
            return -Math.round(Math.pow(Math.round(width / containerWidth), 1));
        }
        else if (width < height) {
            return -Math.round(Math.pow(Math.round(height / containerHeight), 1));
        }
    return 0;
}

export function getScaleLevel(minZoom: number, currentZoom: number)
{
    return Math.round(minZoom < 0 ? currentZoom - minZoom : Math.abs(minZoom) + currentZoom);
}

const Map: React.FC<MapProps>
    = ({
        url,
        elementList,
        zoom = -2,
        onDelete,
        onSetPosition,
        onEdit,
        onAlignment
    }) =>
    {
        const { authorizationLevel } = useAuthorization();

        const [isLoaded, setIsLoaded] = useState<boolean>(false);
        const width = useRef<number>(0);
        const height = useRef<number>(0);
        const mapRef = useRef<MapType | null>(null);
        const [_scale, setScale] = useState<number>(zoom);
        const [bounds, setBounds] = useState<LatLngBoundsExpression>([[0, 0]]);
        const [minZoom, setMinZoom] = useState<number>(0);
        const [selectedMarkers, setSelectedMarkers] = useState<SystemElement[]>([]);

        function renderMap()
        {
            setIsLoaded(true);
        }

        useEffect(() =>
        {
            const img = new Image();
            img.src = url;
            img.onload = () =>
            {
                width.current = img.width;
                height.current = img.height;
                const bounds = [[0, 0], [img.height, img.width]] as LatLngBoundsExpression;
                const size = mapRef.current?.getSize() || { x: 0, y: 0 };
                const minZoom = getMinZoom(img.width, img.height, size.x, size.y);
                mapRef.current?.setMinZoom(minZoom);
                mapRef.current?.fitBounds(bounds);

                setBounds(bounds);
                setScale(getScaleLevel(minZoom, mapRef.current?.getZoom() || 0));
                //setZoom(minZoom);
                setMinZoom(minZoom);
                renderMap();
            };
        }, [url, elementList, minZoom, width, height]);

        function handleClick(coords: Coords)
        {
            if (!(authorizationLevel === AuthorizationLevel.LEVEL3)) return;
            const { systemPlanX, systemPlanY } = coords;

            onSetPosition({ systemPlanX, systemPlanY });
        }

        function where(element1: SystemElement, element2: SystemElement)
        {
            return element1.groupId === element2.groupId! &&
                element1.id === element2.id! &&
                element1.numberGroup === element2.numberGroup;
        }

        function isSelectedMarker(element: SystemElement | null)
        {
            if (!element) return false;

            if (selectedMarkers.length === 0) return false;

            let selected = false;

            for (const _element of selectedMarkers) {

                if (where(element, _element)) {
                    selected = true;
                    break;
                }
            }
            return selected;
        }

        function findSelectedMarkerIndex(element: SystemElement)
        {
            return selectedMarkers.findIndex((marker) =>
            {
                return where(element, marker);

            });
        }

        function selectMarker(element: SystemElement)
        {
            setSelectedMarkers([...selectedMarkers, element]);
        }
        function unSelectMarker(element: SystemElement)
        {
            const index = findSelectedMarkerIndex(element);
            const markerList = selectedMarkers.filter((e, i) => i !== index);
            setSelectedMarkers(markerList);
        }

        // CRS.Simple take the form of [y (height), x (width)] instead of [x, y], in the same way Leaflet uses [lat, lng] instead of [lng, lat].
        return (isLoaded ?
            <>
                <MapContainer
                    attributionControl={false}
                    className={`${style.leafletContainer}`}
                    zoomAnimation={false}

                    markerZoomAnimation={false}
                    center={[
                        height.current / 2,
                        width.current / 2
                    ]}
                    ref={mapRef}
                    maxZoom={5}
                    doubleClickZoom={false}
                    crs={CRS.Simple}
                >
                    <ImageOverlay
                        url={url}
                        bounds={bounds}
                        alt='plan'
                        zIndex={10}
                    />
                    {elementList.map((item, i) =>
                    {
                        return <Marker
                            key={i}
                            planHeight={height.current}
                            planWidth={width.current}
                            zoom={_scale}
                            selected={isSelectedMarker(item)}
                            element={item}
                            selectedElements={selectedMarkers}
                            onAlignment={(elements) => 
                            {
                                if (!(authorizationLevel === AuthorizationLevel.LEVEL3)) return;
                                onAlignment(elements);
                                setSelectedMarkers([]);
                            }}
                            onSelect={(selectedElement) =>
                            {
                                if (!(authorizationLevel === AuthorizationLevel.LEVEL3)) return;
                                if (isSelectedMarker(selectedElement)) {
                                    unSelectMarker(selectedElement);

                                } else {
                                    selectMarker(selectedElement);
                                }
                            }}
                            onChange={onEdit}
                            onDelete={(element) =>
                            {
                                if (onDelete) onDelete(element);
                            }}
                        />;
                    })}
                    <EventClick onClick={handleClick} />
                    <EventZoom
                        onZoom={() =>
                        {
                            const zoom = mapRef.current?.getZoom();
                            const scale = getScaleLevel(minZoom, zoom || 0);

                            setScale(scale);
                        }} />
                </MapContainer>
                <></>

            </>
            : null
        );
    };

export default Map;
