import { useL10n } from "@ews/react-localization-context";
import { IonButton, IonCol, IonGrid, IonInput, IonLabel } from "@ionic/react";
import { SwiperSlide, useSwiper } from 'swiper/react';

import
{
    NoDeviceNumber,
    NumberGroup
} from '@ews/zlt-events';

import { SystemGroup } from "@ews/react-data";
import { FC, ReactNode, useRef, useState } from "react";
import { useCurrentSystemId } from "../../Authorization";
import FilterList from "../../FilterList/FilterList";
import { useIsMobile } from "../../Responsive";
import SettingsZoneRangeInput from "../../Settings/SettingsComponents/SettingsRangeInput/components/Zone/SettingsZoneRangeInput";
import SettingsSwiper from "../../Settings/SettingsComponents/SettingsSwiper/SettingsSwiper";
import GroupItem from "../GroupItem/GroupItem";
import groupStyle from "./GroupOverview.module.scss";
import { firstLetterUpperCase } from "./helper";

type RangeFilter = {
    startGroup: string,
    startElement: string,
    endGroup: string,
    endElement: string;
};

type FilterGroups = {
    [NumberGroup.Zone]?: RangeFilter;
    [NumberGroup.ActuationDevice]?: RangeFilter;
    [NumberGroup.FloodingZone]?: RangeFilter;
    [NumberGroup.AlarmingDevice]?: RangeFilter;
    [NumberGroup.TransmissionDevice]?: RangeFilter;
};

const SliderComponentDesktop: FC<{
    children: React.ReactNode,
    className?: string;
}> = ({ children, className }) => <section className={`${groupStyle["slider-section-componente"]} ${className}`} > {children}</section >;

const Container: FC<{ className?: string, children: ReactNode, style?: Record<string, string>; }> = ({ children, style, className }) =>
{
    const mobileView = useIsMobile();
    return mobileView ? <>{children}</> : <div className={className} style={style}>{children}</div>;
};

const elementZoneCompare = (zone: number, element: number, zone_cmp: number, element_cmp: number) =>
{
    if (element === NoDeviceNumber) {
        element = element_cmp;
    }

    return (((BigInt(zone) << BigInt(16)) | BigInt(element)) - ((BigInt(zone_cmp) << BigInt(16)) | BigInt(element_cmp))) < 0 ? -1 : 1;
};

const parseRangeFilter = (filter: string) =>
{
    return filter.split("/");
};

let filterState: FilterGroups = {};
let persistenceId: string = "";

const GroupOverview: React.FC<{
    groups: SystemGroup[],
    isFilterOpen?: boolean,
    onIsOpen?: (status: boolean) => void;
}> = ({
    groups,
    isFilterOpen,
    onIsOpen
}) =>
    {
        const slideToRef = useRef<number>(0);
        const SwiperRef = useRef<any>();
        const systemId = useCurrentSystemId();
        if (persistenceId !== systemId) {
            filterState = {};
            persistenceId = systemId;
        }


        const [rangeFilter, setRangeFilter] = useState<FilterGroups>(filterState);

        const mobileView = useIsMobile();
        const { translate: t } = useL10n();

        const buttonTitelList = ["[SID_MNU_TAB_GROUPS]", "[SID_MNU_TAB_CONTROLS]", "[SID_MNU_TAB_EXT_SEC]", "[SID_MNU_TAB_AL_DEVICES]", "[SID_MNU_TAB_TR_DEVICES]"];
        const [currentPage, setCurrentPage] = useState(buttonTitelList[0]);

        const SwiperButtonNext: React.FC<{ swipeRef: any; }> = ({ swipeRef }) =>
        {
            const swiper = useSwiper();
            return <IonInput
                label=""
                ref={swipeRef}
                onClick={(e) =>
                {

                    swiper.slideTo(slideToRef.current);
                }}
                hidden />;
        };

        type List = {
            [NumberGroup.Zone]: SystemGroup[];
            [NumberGroup.ActuationDevice]: SystemGroup[];
            [NumberGroup.FloodingZone]: SystemGroup[];
            [NumberGroup.AlarmingDevice]: SystemGroup[];
            [NumberGroup.TransmissionDevice]: SystemGroup[];
        };

        const groupList: List = {
            [NumberGroup.Zone]: [],
            [NumberGroup.ActuationDevice]: [],
            [NumberGroup.FloodingZone]: [],
            [NumberGroup.AlarmingDevice]: [],
            [NumberGroup.TransmissionDevice]: [],
        };

        groups.forEach((group) =>
        {
            const type = group.numberGroup as keyof List;
            const filter = rangeFilter[type];

            if (filter) {

                if (elementZoneCompare(group.id!, NoDeviceNumber, parseInt(filter.startGroup), parseInt(filter.startElement)) >= 0 &&
                    elementZoneCompare(group.id!, NoDeviceNumber, parseInt(filter.endGroup), parseInt(filter.endElement)) <= 0) {

                    const elements = group.elements?.filter((e) =>
                    {
                        return elementZoneCompare(e.groupId!, e.id!, parseInt(filter.startGroup), parseInt(filter.startElement)) >= 0 &&
                            elementZoneCompare(e.groupId!, e.id!, parseInt(filter.endGroup), parseInt(filter.endElement)) <= 0;
                    });

                    group.elements = elements;

                } else {
                    // console.log(filter, type, rangeFilter);
                    return;
                }

            }

            groupList[type].push(group);

        });

        const SwiperComponent = mobileView ? SettingsSwiper : IonGrid;
        const SliderComponent = mobileView ? SwiperSlide : SliderComponentDesktop;

        return (<>
            <div className={`${groupStyle["group-card-content-container"]} ${mobileView ? '' : 'ion-padding'}`} >

                {mobileView && <FilterList
                    onIsOpen={onIsOpen}
                    isOpen={isFilterOpen}
                    isDetailsHidden={false}

                    details={<>
                        {Object.keys(groupList).map((key) =>
                        {
                            if (parseInt(key) === NumberGroup.FloodingZone && !groupList[NumberGroup.FloodingZone].length) {
                                return <></>;
                            }

                            return <IonCol key={key} sizeLg="3" sizeMd="1" sizeSm="1">
                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                    <IonLabel style={{ maxWidth: "fit-content" }}>{t(`GROUP_RANGE_PICKER_${key}`)}</IonLabel>
                                </div>

                                <SettingsZoneRangeInput
                                    lines="none"
                                    divider={false}
                                    value={rangeFilter[key as unknown as keyof FilterGroups] || {}}
                                    labels={{ start: "from", end: "to" }}
                                    inputLabels={{ parent: "Nummer", children: "Element" }}
                                    position={{ parent: "row", children: "line" }}
                                    reset={false}
                                    onChange={(value) =>
                                    {
                                        const [startGroup, startElement] = parseRangeFilter(value.startZoneElement!);
                                        const [endGroup, endElement] = parseRangeFilter(value.endZoneElement!);

                                        rangeFilter[key as unknown as keyof FilterGroups] = { startGroup, startElement, endGroup, endElement };
                                        filterState = rangeFilter;

                                        setRangeFilter({ ...rangeFilter });

                                    }} />

                            </IonCol>;
                        })}


                    </>
                    } />}

                <SwiperComponent loop
                    className={groupStyle["group-swiper"]}
                >

                    {mobileView && <SwiperButtonNext swipeRef={SwiperRef} />}
                    {mobileView ? <SliderComponent key={'group-overview-slider-main'} className={mobileView ? groupStyle["slide-height"] : ""}>
                        <div className={groupStyle.buttonContainer}>
                            {buttonTitelList.map((title, index) =>
                            {
                                if (title === "[SID_MNU_TAB_EXT_SEC]") {
                                    if (!groupList[NumberGroup.FloodingZone].length) return <></>;
                                }

                                return <div key={index} className={groupStyle["button-wrapper"]} data-x={title}>
                                    <IonButton
                                        expand="full"
                                        color='primary'
                                        className={groupStyle["gp-btn"]}
                                        onClick={() =>
                                        {
                                            slideToRef.current = index + 1;
                                            SwiperRef.current.click();
                                        }}
                                    >
                                        <div className={groupStyle["pull-left"]}>{t(firstLetterUpperCase(title))}</div>

                                    </IonButton>
                                </div>;
                            })}
                        </div>
                    </SliderComponent> : null}

                    <Container className={groupStyle['container']}>

                        <Container className={groupStyle['group-container']}>


                            {
                                mobileView ? <>
                                    <SliderComponent className={groupStyle['group-swiper']}>
                                        <GroupItem groups={groupList ? groupList[NumberGroup.Zone] : []} title={buttonTitelList[0]} />
                                    </SliderComponent>

                                    <SliderComponent className={groupStyle['group-swiper']}>
                                        <GroupItem groups={groupList ? groupList[NumberGroup.ActuationDevice] : []} title={buttonTitelList[1]} />
                                    </SliderComponent>

                                    {groupList[NumberGroup.FloodingZone].length ? <SliderComponent className={groupStyle['group-swiper']}>
                                        <GroupItem groups={groupList ? groupList[NumberGroup.FloodingZone] : []} title={buttonTitelList[2]} />
                                    </SliderComponent> : <></>}

                                    <SliderComponent className={groupStyle['group-swiper']}>
                                        <GroupItem groups={groupList ? groupList[NumberGroup.AlarmingDevice] : []} title={buttonTitelList[3]} />
                                    </SliderComponent>

                                    <SliderComponent className={groupStyle['group-swiper']}>
                                        <GroupItem groups={groupList ? groupList[NumberGroup.TransmissionDevice] : []} title={buttonTitelList[4]} />
                                    </SliderComponent>

                                </> : <>
                                    <GroupItem className={currentPage === buttonTitelList[0] ? "" : groupStyle.none} groups={groupList ? groupList[NumberGroup.Zone] : []} title={buttonTitelList[0]} />
                                    <GroupItem className={currentPage === buttonTitelList[1] ? "" : groupStyle.none} groups={groupList ? groupList[NumberGroup.ActuationDevice] : []} title={buttonTitelList[1]} />
                                    {groupList[NumberGroup.FloodingZone].length ? <GroupItem className={currentPage === buttonTitelList[2] ? "" : groupStyle.none} groups={groupList ? groupList[NumberGroup.FloodingZone] : []} title={buttonTitelList[2]} /> : <></>}
                                    <GroupItem className={currentPage === buttonTitelList[3] ? "" : groupStyle.none} groups={groupList ? groupList[NumberGroup.AlarmingDevice] : []} title={buttonTitelList[3]} />
                                    <GroupItem className={currentPage === buttonTitelList[4] ? "" : groupStyle.none} groups={groupList ? groupList[NumberGroup.TransmissionDevice] : []} title={buttonTitelList[4]} />
                                </>
                            }
                        </Container>
                        {!mobileView ? <div className={groupStyle.buttonContainer}>
                            {buttonTitelList.map((title, index) =>
                            {
                                if (title === "[SID_MNU_TAB_EXT_SEC]") {
                                    if (!groupList[NumberGroup.FloodingZone].length) return <></>;
                                }

                                return <div key={index}>
                                    <IonButton
                                        expand="full"
                                        color={currentPage === title ? 'secondary' : 'primary'}
                                        onClick={() => { setCurrentPage(title); }}
                                    >{t(firstLetterUpperCase(title))}</IonButton>
                                </div>;
                            })}
                        </div> : null}
                    </Container>
                </SwiperComponent>
            </div >
        </>
        );
    };
export default GroupOverview;
