import { SystemGroup, SystemPlan } from "@ews/react-data";
import { AuthorizationLevel } from "@ews/zlt-events";
import { IonCard, IonCardContent } from "@ionic/react";
import React, { ReactNode, useEffect, useState } from "react";
import { useAuthorization } from "../Authorization";
import Fullscreen from "../Fullscreen/Fullscreen";
import { useNamedRoutes } from "../NamedRoutes";
import { SystemElement, SystemSetup, useModifySystemElement, useModifySystemPlan, useSystemPlans, useSystemSetup } from "../ReactData/system";
import { useIsMobile } from "../Responsive";
import style from "./Plan.module.scss";
import { getMarkerId } from "./components/Element/Element";
import ElementList from "./components/ElementList/ElementList";
import Floors from "./components/Floors/Floors";
import FloorEdit from "./components/Floors/components/FloorEdit/FloorEdit";
import Map from "./components/Map/Map";
import { Device, PlanProps } from "./types";

export const fileIconSize = "";

function getMarkers(groups: SystemGroup[], systemPlanId: string)
{
    const markers: SystemElement[] = [];

    const where = (element: SystemElement): Boolean =>
    {
        return element.systemPlanX !== null && element.systemPlanX !== null && element.systemPlanId === systemPlanId;
    };

    groups.filter(group => group.elements?.length).forEach((group) =>
    {
        group.elements?.forEach((element: SystemElement) =>
        {
            if (where(element)) markers.push(element);
        });
    });
    return markers;
}


function getGroupsWithUnsetElements(groups: SystemGroup[])
{
    const where = (element: SystemElement) => (!("systemPlanX" in element) || element?.systemPlanX === null) && (!("systemPlanY" in element) || element?.systemPlanY === null);

    const copy = [...groups];

    return copy.map(group =>
    {

        const copyGroup = { ...group } as SystemGroup;

        if (copyGroup.elements?.length) {
            copyGroup.elements = copyGroup.elements.filter((element: SystemElement) => where(element));

        };
        return copyGroup;
    });
}

const Aside: React.FC<{ children: ReactNode, mobileView: boolean; }> = ({ children, mobileView }) =>
{
    return mobileView ? <>{children}</> : <aside>{children}</aside>;
};

const Plan: React.FC<PlanProps> = ({ onClick }) =>
{
    const { params } = useNamedRoutes();

    const { id, planId } = params();

    const setup = useSystemSetup(id!);

    const floors = useSystemPlans(id || "");

    const mobileView = useIsMobile();

    const modifyElement = useModifySystemElement();

    const { authorizationLevel } = useAuthorization();

    const [editFloor, setEditFloor] = useState<SystemPlan | undefined>(undefined);
    const [currentElement, setCurrentElement] = useState<SystemElement | undefined>(undefined);
    const [currentGroup, setCurrentGroup] = useState<SystemGroup | undefined>(undefined);
    const [currentIcon, setCurrentIcon] = useState<Device | undefined>(undefined);
    const [currentFloor, setCurrentFloor] = useState<SystemPlan | undefined>(undefined);
    const [bounds, setBounds] = useState<[number, number]>([0, 0]);
    const [markers, setMarkers] = useState<(SystemElement)[]>([]);
    const [unsetElements, setUnsetElements] = useState<SystemGroup[]>([]);
    const [_floors, setFloors] = useState(floors);


    const modifySystemPlan = useModifySystemPlan();

    async function _modifySystemPlan(systemId: string, data: SystemPlan)
    {
        const results = await modifySystemPlan(systemId, data as SystemPlan, data.id!);
        setFloors(results);
    }


    function filterSetup(setup: SystemSetup, id: string)
    {
        const markers = getMarkers(setup.groups, id);
        const unsetMarkers = getGroupsWithUnsetElements(setup.groups);
        setMarkers(markers);
        setUnsetElements(unsetMarkers);
    }

    async function setMarker(
        coords: { systemPlanY: number, systemPlanX: number; },
    )
    {

        if (currentElement) {
            const element = {
                systemPlanIcon: currentIcon?.img || "",
                systemPlanId: currentFloor?.id || "",
                systemPlanX: coords.systemPlanX,
                systemPlanY: coords.systemPlanY
            };


            await modifyElement(
                currentElement.systemId!,
                currentElement?.numberGroup!,
                currentElement?.groupId!,
                currentElement?.id!,
                element as any
            );
            setCurrentElement(undefined);
        }
    }

    async function deleteMarker(element: SystemElement)
    {
        await modifyElement(
            element.systemId!,
            element?.numberGroup!,
            element?.groupId!,
            element?.id!,
            {
                ...element,
                systemPlanId: null,
                systemPlanX: null,
                systemPlanY: null,
                systemPlanIcon: null,
            } as any,
        );
    }


    async function modifyMarker(element: SystemElement, modified: { [key: string]: any; })
    {
        await modifyElement(
            element.systemId!,
            element.numberGroup!,
            element.groupId!,
            element.id!,
            { ...modified } as any,
        );
    }

    useEffect(() =>
    {
        if (floors) setFloors(floors);
    }, [floors]);


    useEffect(() =>
    {
        if (floors && floors.length && setup.groups.length) {

            if (planId && floors.findIndex((floor) => floor.id === planId) > -1) {
                const floor = floors[floors.findIndex((floor) => floor.id === planId)];
                setCurrentFloor(floor);
                filterSetup(setup, floor.id!);
            }
            else if (!currentFloor) {
                setCurrentFloor(floors[0]);
                filterSetup(setup, !currentFloor ? floors[0].id! : (currentFloor as SystemPlan).id!);
            }
        }

        return () =>
        {
            markers.forEach((marker) => document.querySelector("#" + getMarkerId(marker))?.remove());
        };
    },
        [_floors, setup, currentFloor, planId]
    );

    return <>
        <Fullscreen>
            <IonCard className={`${style.plan}`}>
                <IonCardContent>
                    <div className={`${style.contentContainer}`}>
                        <main>
                            {currentFloor ?
                                <Map
                                    zoom={mobileView ? -3 : -2}
                                    onDelete={deleteMarker}
                                    onEdit={modifyMarker}
                                    onSetPosition={setMarker}
                                    onAlignment={(elements) =>
                                    {
                                        elements.forEach(element =>
                                        {
                                            modifyMarker(element, element);
                                        });
                                    }}
                                    url={currentFloor.image!}
                                    elementList={markers}
                                />
                                : null
                            }
                        </main>
                        {<Aside mobileView={mobileView}>
                            <Floors
                                systemId={id}
                                current={currentFloor}
                                floors={_floors}
                                onChange={(floor) =>
                                {
                                    setCurrentFloor(floor);
                                    setBounds(bounds);
                                }}
                                onContext={(floor) =>
                                {
                                    setEditFloor(floor);
                                }}
                            />
                            {mobileView || !(authorizationLevel === AuthorizationLevel.LEVEL3) ?
                                null
                                :
                                <ElementList
                                    currentGroup={currentGroup}
                                    currentElement={currentElement}
                                    groups={unsetElements}
                                    iconData={currentIcon}
                                    onElement={(element) => setCurrentElement(element)}
                                    onGroup={(group) => setCurrentGroup(group)}
                                    onChange={(icon) => setCurrentIcon(icon)}
                                />}
                        </Aside>}
                    </div>
                    {mobileView ?
                        null
                        :
                        <FloorEdit
                            systemId={id || ""}
                            floors={floors}
                            floor={editFloor}
                            onDelete={(planId) =>
                            {
                                markers.forEach(marker =>
                                {
                                    if (marker.systemPlanId === planId) {
                                        deleteMarker(marker);
                                    }
                                });
                                if (currentFloor?.id === planId) setCurrentFloor(undefined);
                            }}
                            onModified={(modifiedFloor) => { _modifySystemPlan(id!, modifiedFloor); }}
                            onDismiss={() => { setEditFloor(undefined); }}
                        />
                    }
                </IonCardContent>
            </IonCard >

        </Fullscreen>
    </>;
};

export default Plan;
