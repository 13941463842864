import { SystemShareOperate } from '@ews/react-data';
import { useModifySystemShareOperate, useSystemPermissions, useSystemShareOperate } from '../../../../../../../ReactData/system';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import SettingsCheckbox from '../../../../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox';
import NumberRange from '../components/NumberRange';
import { Props } from './types';
import { useDialog } from '../../../../../../../Dialog';
import { useL10n } from '@ews/react-localization-context';
import { booleanFilter } from '../../../../../../../FilterList/FilterList';
import { useState } from 'react';

const Operation: React.FC<Props> = ({ systemId, userId }) =>
{
    const operation = useSystemShareOperate(systemId || "", userId!);
    const modify = useModifySystemShareOperate();
    const modifyData = async (data: SystemShareOperate) => await modify(systemId!, userId!, data);
    const [toggle, reset] = useState<boolean>(true);
    const dialog = useDialog();
    const { translate: t } = useL10n();

    const warn = async () =>
    {
        return await dialog({
            "title": t('Overriding system defaults'),
            "message": t('This setting will overrule, what has been on the system. Are you sure, you want to do this'),
            "cancelButtonTitle": t('Dismiss'),
            "confirmButtonTitle": t('Confirm')
        });
    };

    return <SettingsCol
    //    size='12' xl='12' md='12' lg='12' sm='12'
    >
        <SettingsCheckbox
            text={"WLAN check active"}
            checked={operation.restrictOperationToWlanSSID || false}
            onChange={async (restrictOperationToWlanSSID: boolean) =>
            {
                if (await warn()) {
                    modifyData({ restrictOperationToWlanSSID } as SystemShareOperate);
                } else {
                    reset(!toggle);
                }
            }} />

        <SettingsCheckbox
            text={"GPS check active"}
            checked={operation.restrictOperationToGPSArea || false}
            onChange={async (restrictOperationToGPSArea: boolean) =>
            {
                if (await warn()) {
                    modifyData({ restrictOperationToGPSArea } as SystemShareOperate);
                } else {
                    reset(!toggle);
                }
            }} />

        <SettingsCheckbox
            text={"Only one user in authorization"}
            checked={operation.exclusiveAuthorization || false}
            onChange={async (exclusiveAuthorization: boolean) =>
            {
                if (await warn()) {
                    modifyData({ exclusiveAuthorization } as SystemShareOperate);
                } else {
                    reset(!toggle);
                }
            }} />

        <SettingsCheckbox
            text={"Operation only possible if FDCP is in authorization level 2/3"}
            checked={operation.restrictOperationToAuthorizedUsers || false}
            onChange={async (restrictOperationToAuthorizedUsers: boolean) =>
            {
                if (await warn()) {
                    modifyData({ restrictOperationToAuthorizedUsers } as SystemShareOperate);
                } else {
                    reset(!toggle);
                }
            }} />

        <SettingsCheckbox
            text={"If session is enabled on the FDCP"}
            checked={operation.restrictOperationToApprovedSession || false}
            onChange={async (restrictOperationToApprovedSession: boolean) =>
            {
                if (await warn()) {
                    modifyData({ restrictOperationToApprovedSession } as SystemShareOperate);
                } else {
                    reset(!toggle);
                }
            }} />

        <NumberRange
            label='Zone'
            from={operation.fromZone}
            to={operation.toZone}
            onChange={(fromZone, toZone) => modifyData({ fromZone, toZone } as SystemShareOperate)}
        />

        <NumberRange
            label='Action'
            from={operation.fromActuation}
            to={operation.toActuation}
            onChange={(fromActuation, toActuation) => modifyData({ fromActuation, toActuation } as SystemShareOperate)}
        />

        <NumberRange
            label='AlarmingDevice'
            from={operation.fromAlarmingDevice}
            to={operation.toAlarmingDevice}
            onChange={(fromAlarmingDevice, toAlarmingDevice) => modifyData({ fromAlarmingDevice, toAlarmingDevice } as SystemShareOperate)}
        />
        <NumberRange
            label='TransmissionDevice'
            from={operation.fromTransmissionDevice}
            to={operation.toTransmissionDevice}
            onChange={(fromTransmissionDevice, toTransmissionDevice) => modifyData({ fromTransmissionDevice, toTransmissionDevice } as SystemShareOperate)}
        />

    </SettingsCol>;
};
export default Operation;
