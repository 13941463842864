import { useL10n } from '@ews/react-localization-context';
import { IonButton, IonRow, IonText } from '@ionic/react';
import { useMyDateFormat } from '../../../../../../../Login/LoginProvider';
import { useDeleteSystemShare, useModifySystemShare, useSystemShare } from '../../../../../../../ReactData/system';
import SettingsCol from '../../../../../../../Settings/SettingsCol/SettingsCol';
import SettingsLabel from '../../../../../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel';
import { Props } from './types';
import { SystemShare, SystemShareType } from '@ews/react-data';
import { useNamedRoutes } from '../../../../../../../NamedRoutes';
import ValidityCountDown from '../../../ValidityCountDown';
import SettingsCheckbox from '../../../../../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox';
import SettingsTextInput from '../../../../../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput';

const General: React.FC<Props> = ({ systemId, userId }) =>
{

    //return <></>;
    const share = useSystemShare(systemId || "", userId || "") as any;

    const { translate: t } = useL10n();
    const { generate, router } = useNamedRoutes();
    const formatDate = useMyDateFormat();
    const modifyShare = useModifySystemShare();
    const deleteShare = useDeleteSystemShare();

    const handleDelete = async () =>
    {
        await deleteShare(share.systemId, share.userId);
        const redirect = generate('system:shares', { id: systemId });
        router?.push(redirect);
    };


    return <SettingsCol
        size='12' xl='12' md='12' lg='12' sm='12'
    >
        <SettingsLabel text={'System-ID'} value={share.systemNumber} />
        <SettingsLabel text={'Bearbeitet am'} value={share.modified ? formatDate(new Date(share.modified)) : ""} />
        <SettingsLabel text={'Erstellt am'} value={share.created ? formatDate(new Date(share.created)) : ""} />
        <SettingsLabel text={'Kunden-ID'} value={share.customerNumber} />
        <SettingsLabel text={'Kundenname'} value={share.customerName} />
        <SettingsLabel text={'Benuzter-ID'} value={share.username} />
        <SettingsLabel text={'Benuztername'} value={[share.userLastName, share.userFirstName].join(", ")} />
        <SettingsLabel text={'Share type'} value={t(`SHARE_TYPE_${share.type}`)} />
        {share.type === SystemShareType.MODIFIED &&
            <IonRow class="ion-justify-content-end">
                <IonButton
                    color={"success"}
                    onClick={() => modifyShare(share.systemId, share.userId, { type: SystemShareType.DEFAULT } as SystemShare)}
                >
                    {t('Freigabe zurücksetzen')}
                </IonButton>
            </IonRow>
        }
        {share.type === SystemShareType.EXTENDED && <>
            <SettingsCheckbox
                text={"Freigabe zeitlich begrenzen"}
                checked={share.validFor !== -1}
                onChange={(validFor) =>
                {
                    if (validFor) {
                        modifyShare(share.systemId, share.userId, { validFor: 120, validFrom: new Date() } as SystemShare);
                    } else {
                        modifyShare(share.systemId, share.userId, { validFor: -1, validFrom: null } as SystemShare);
                    }
                }}>
                <SettingsTextInput
                    lines="none"
                    text={"auf wie viele Stunden"}
                    textAlign='right'
                    value={share.validFor}
                    onBlur={({ value }) =>
                    {
                        modifyShare(share.systemId, share.userId, { validFor: value, validFrom: new Date() } as SystemShare);
                    }} />
            </SettingsCheckbox>
            <SettingsLabel text={'Valid for'} children={share.validFor >= 0 ? <ValidityCountDown validFor={share.validFor} validFrom={new Date(share.validFrom)} /> : <IonText style={{ float: "right" }}>{t('unbegrenzt')}</IonText>} />
            <IonRow class="ion-justify-content-end">
                <IonButton
                    color={"danger"}
                    onClick={handleDelete}
                >
                    {t('Freigabe löschen')}
                </IonButton>
            </IonRow>
        </>
        }

    </SettingsCol>;
};
export default General;
