
import { SystemElement, SystemRTM } from "@ews/react-data";
import Icon from "@ews/react-icons";
import { useL10n } from "@ews/react-localization-context";
import { AuthorizationLevel, NoDeviceNumber, eventGetSymbol, formatLogicalAddress } from "@ews/zlt-events";
import { IonButton, IonInput, IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import { DivIcon, Icon as Licon } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { Marker, Popup, Tooltip } from 'react-leaflet';
import { useAuthorization } from "../../../Authorization";
import { Devices, deviceList } from "../../deviceList";
import style from "./Element.module.scss";
import { Alignment, ElementProps, alignments, fontFamilies, fontWeights, labelPosition } from "./types";

const defaultFontSize = 6;
const defaultIconSize = 12;

export function getDeviceIconsFilePath(file: string)
{
    return `assets/device-icons/${file}.png`;
}

function convertStringToRoundedNumber(value?: string | number)
{
    return Number(Number(value || 0).toFixed(0));
}

function getScaleSize(originSize: number, zoom: number, factor: number,)
{
    let result = Math.pow(2, zoom) * originSize;
    return result;
}

function getLabelOffset(alignment: Alignment, iconSize: number): [number, number]
{
    let size: [number, number] = [0, 0]; // [width, height]

    if (alignment === "bottom") {
        size[1] = iconSize;
    }
    else if (alignment === "top") {
        size[1] -= iconSize;
    }
    else if (alignment === "left") {
        size[0] = -iconSize;
    }
    else if (alignment === "right") {
        size[0] = iconSize;
    }
    return size;
}

function getPosition(elements: SystemElement[], alignment: Alignment)
{
    if (alignment === "bottom") {
        const numbers = elements.map(element => Number(element.systemPlanX || 0));
        return { systemPlanX: Math.min(...numbers) };
    }
    else if (alignment === "top") {
        const numbers = elements.map(element => Number(element.systemPlanX || 0));
        return { systemPlanX: Math.max(...numbers) };
    }
    else if (alignment === "left") {
        const numbers = elements.map(element => element.systemPlanY || 0);
        return { systemPlanY: Math.min(...numbers) };
    }
    else if (alignment === "right") {
        const numbers = elements.map(element => element.systemPlanY || 0);
        return { systemPlanY: Math.max(...numbers) };
    }
    return null;
}
export function getMarkerId(element: SystemElement)
{
    return `marker${element.groupId}_${element.id}_${element.numberGroup}`;
}


const Element: React.FC<ElementProps> = ({
    element,
    selectedElements,
    onAlignment,
    onSelect,
    onChange,
    onDelete,
    zoom,
    selected = false,
    planHeight = 0,
    planWidth = 0
}) =>
{

    const { translate: t } = useL10n();
    const { authorizationLevel } = useAuthorization();
    const markerRef = useRef<any>(null);

    const tooltipLabelDirection = element?.systemPlanStyle?.labelPosition || "bottom";

    function checkImage(
        path: string,
        onload: (path: string) => void,
        onerror: (e: string | Event) => void
    )
    {
        const img = new Image();

        img.id = getMarkerId(element);
        //img.loading = "lazy";
        img.alt = "";
        img.height = 0;
        img.width = 0;

        const domElement = document.querySelector("#" + img.id);
        if (!domElement) {
            document.body.append(img);
        }

        img.onload = () =>
        {
            img.hidden = true;
            onload(path);
        };
        img.onerror = (e) =>
        {
            onerror(e);
            if (domElement) {
                domElement.remove();
            }
        };
        img.src = path;
    }


    function setFont(value: any, type: keyof SystemElement["systemPlanStyle"])
    {
        if (!("systemPlanStyle" in element)) {
            element.systemPlanStyle = {};
        }
        element.systemPlanStyle![type] = value;
    }

    const { systemPlanX, systemPlanY, systemPlanIcon, text, groupId, id, rtm, eventKindType } = element;

    const logicalAddress = `${formatLogicalAddress(groupId!, id || NoDeviceNumber, 1)} ${text}`;

    const scale = getScaleSize(defaultIconSize, zoom || 0, 2);
    const iconSize = scale * (element.systemPlanStyle?.iconScale || 1);


    const [icon, setIcon] = useState<Licon | null>(null);
    const [alignment, setAlignment] = useState<Alignment | null>(null);
    function getClassName(selected: boolean, eventKindType?: number,)
    {
        let className = ``;
        if ((deviceList[systemPlanIcon as keyof Devices][eventKindType || 0])) {
            const deviceEvent = deviceList[systemPlanIcon as keyof Devices][eventKindType || 0];
            className = `${deviceEvent.blink ? style.blink : ""}`;
        }
        if (selected) {
            className += ` ${style.selected}`;
        }
        return className;
    }

    function _setIcon(path: string,)
    {
        const icon = new Licon({
            className: "",
            iconUrl: getDeviceIconsFilePath("question"),
            iconSize: [iconSize, iconSize],
        });

        checkImage(path, (_path) =>
        {
            icon.options.iconUrl = _path;

            icon.options.className = getClassName(selected, eventKindType);
            setIcon(new Licon({ ...icon.options }));
        },
            (error) =>
            {
                icon.options.iconUrl = getDeviceIconsFilePath("question");
                icon.options.className = "";
                setIcon(new Licon({ ...icon.options }));
            }
        );
    }

    useEffect(() =>
    {
        if (systemPlanIcon) {
            const path = getDeviceIconsFilePath(`${""}${systemPlanIcon}_${eventKindType || 0}`);
            _setIcon(path);
        }
        return () => { };
    }, [eventKindType, zoom, selected, systemPlanIcon]);

    if (
        icon && icon.options.iconUrl !== getDeviceIconsFilePath("question") &&
        getDeviceIconsFilePath(`${""}${systemPlanIcon}_${eventKindType || 0}`) !== icon?.options.iconUrl
    ) {
        icon.options.iconUrl = getDeviceIconsFilePath(`${""}${systemPlanIcon}_${eventKindType || 0}`);
        icon.options.className = getClassName(selected, eventKindType);
        setIcon(new Licon({ ...icon.options }));
    };
    if (icon && iconSize !== (icon?.options.iconSize as [number, number])[0]) {
        setIcon(new Licon({ ...icon.options, iconSize: [iconSize, iconSize] }));
    }



    return !icon ? null : <>
        <Marker
            interactive={true}
            ref={markerRef}
            eventHandlers={{
                contextmenu: (e) =>
                {
                    if (onSelect) onSelect(element);
                }
            }}
            position={[systemPlanX!, systemPlanY!]}
            icon={icon}
        >
            <Popup>
                <section className={style.popover}>
                    <IonItem lines="full">
                        <div slot="start">{t("Name")}:</div>
                        <div slot="end">{text}</div>
                    </IonItem>
                    <IonItem lines="full">
                        <div slot="start">{t("Device")}:</div>
                        {!(authorizationLevel === AuthorizationLevel.LEVEL3) ?

                            <div slot="end">{logicalAddress}</div>
                            :
                            <IonInput
                                slot="end"
                                label=''
                                style={{ minWidth: "100px" }}
                                debounce={900}
                                value={element?.systemPlanStyle?.label || logicalAddress}
                                onIonChange={({ detail }) =>
                                {
                                    if (!detail.value) return;
                                    setFont(detail.value, "label");
                                }} />
                        }
                    </IonItem>
                    {
                        rtm && rtm.length ? <IonItem lines="full">
                            <div className={style.center}>{
                                rtm?.map((event: SystemRTM) =>
                                {
                                    const { zone, element, eventKindType } = event;
                                    const icon = eventGetSymbol(eventKindType, { zone, element });
                                    return <Icon icon={icon} key={`${zone
                                        } /${element}`}></Icon >;
                                })
                            }</div >
                        </IonItem >
                            : null
                    }
                    {
                        !(authorizationLevel === AuthorizationLevel.LEVEL3) ?
                            null
                            :
                            <>
                                <IonItem lines="full">
                                    <div slot="start">Y:</div>
                                    <IonInput
                                        slot="end"
                                        label=''
                                        type='number'
                                        min={0}
                                        max={planWidth}
                                        style={{ width: "100px" }}
                                        debounce={900}
                                        value={convertStringToRoundedNumber(element.systemPlanY)}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            element.systemPlanY = Number(detail.value);
                                        }} />

                                </IonItem>
                                <IonItem lines="full">
                                    <div slot="start">X:</div>
                                    <IonInput
                                        slot="end"
                                        label=''
                                        type='number'
                                        min={0}
                                        max={planHeight}
                                        style={{ width: "100px" }}
                                        debounce={900}
                                        value={convertStringToRoundedNumber(element.systemPlanX)}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            element.systemPlanX = Number(detail.value);
                                        }} />
                                </IonItem>

                                <IonItem lines="full">
                                    <div slot="start">Icon Scale:</div>

                                    <IonInput
                                        slot="end"
                                        label=''
                                        type='number'
                                        style={{ width: "100px" }}
                                        debounce={900}
                                        value={element?.systemPlanStyle?.iconScale || 0}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            setFont(detail.value, "iconScale");
                                        }} />
                                </IonItem>

                                <IonItem lines="full">
                                    <div slot="start">Font Size:</div>

                                    <IonInput
                                        slot="end"
                                        label=''
                                        type='number'
                                        style={{ width: "100px", fontsize: element?.systemPlanStyle?.fontSize }}
                                        debounce={900}
                                        value={element?.systemPlanStyle?.fontSize}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            setFont(detail.value, "fontSize");
                                        }} />
                                </IonItem>

                                <IonItem lines="full">
                                    <IonSelect
                                        value={element?.systemPlanStyle?.labelPosition}
                                        interface="popover"
                                        label={t("Label Position")}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            setFont(detail.value, "labelPosition");

                                        }
                                        }
                                    >
                                        {labelPosition.map(position =>
                                            <IonSelectOption
                                                className={style[position as string]}
                                                key={position}
                                                value={position}
                                            >
                                                {t(position as string)}
                                            </IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonItem>


                                <IonItem lines="full">
                                    <IonSelect
                                        value={element.systemPlanStyle?.fontFamily}
                                        interface="popover"
                                        label={t("Font Family")}
                                        style={{ fontFamily: element.systemPlanStyle?.fontFamily }}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            setFont(detail.value, "fontFamily");
                                        }
                                        }
                                    >
                                        {fontFamilies.map(font =>
                                            <IonSelectOption
                                                className={style[font.class]}
                                                key={font.class}
                                                value={font}
                                            >
                                                {t(font.label as string)}
                                            </IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonItem>

                                <IonItem lines="full">
                                    <IonSelect
                                        value={element.systemPlanStyle?.fontWeight}
                                        interface="popover"
                                        label={t("Font Weight")}
                                        style={{ fontWeight: element.systemPlanStyle?.fontWeight }}
                                        onIonChange={({ detail }) =>
                                        {
                                            if (!detail.value) return;
                                            setFont(detail.value, "fontWeight");
                                        }
                                        }
                                    >
                                        {fontWeights.map(font =>
                                            <IonSelectOption
                                                className={style[font as string]}
                                                style={{ fontFamily: font }}
                                                key={font}
                                                value={font}
                                            >
                                                {t(font as string)}
                                            </IonSelectOption>
                                        )}
                                    </IonSelect>
                                </IonItem>

                                {selected && selectedElements.length ?
                                    <IonItem lines="full">
                                        <IonSelect
                                            value={alignment}
                                            interface="popover"
                                            label={t("Alignment")}
                                            onIonChange={({ detail }) => setAlignment(detail.value)
                                            }
                                        >
                                            {alignments.map(alignment =>
                                                <IonSelectOption
                                                    key={alignment}
                                                    value={alignment}
                                                >
                                                    {t(alignment)}
                                                </IonSelectOption>
                                            )}
                                        </IonSelect>
                                    </IonItem>
                                    : null
                                }
                                <IonItem lines="full">
                                    <div
                                        className={style.buttonContainer}
                                    >
                                        <IonButton
                                            color={"danger"}
                                            onClick={() =>
                                            {
                                                if (onDelete) onDelete(element);
                                            }}>
                                            <IonLabel>{t("Delete")}</IonLabel>
                                        </IonButton>
                                        {selected && selectedElements.length ? <IonButton
                                            disabled={alignment === null}
                                            color={"warning"}
                                            expand='full'
                                            onClick={() =>
                                            {
                                                if (!alignment) return;
                                                const position = getPosition(selectedElements, alignment);

                                                if (!position) return;

                                                const elements = selectedElements.map(element =>
                                                {
                                                    return { ...element, ...position } as SystemElement;
                                                });

                                                if (onAlignment) onAlignment(elements);
                                            }}>
                                            {t("Alignment")}
                                        </IonButton> : null}
                                        <IonButton

                                            color={"success"}
                                            onClick={() =>
                                            {
                                                if (onChange) onChange(element, { ...element });
                                            }}>
                                            <IonLabel>{t("Save")}</IonLabel>
                                        </IonButton>
                                    </div>
                                </IonItem>
                            </>}
                </section >
            </Popup >
            <Tooltip
                offset={getLabelOffset(tooltipLabelDirection, defaultIconSize)}
                interactive={false}
                direction={tooltipLabelDirection}
                permanent
                className={style.tooltip}
            >
                <p
                    className={`
                        ${style.label}
                        ${element.systemPlanStyle?.fontFamily ? fontFamilies.find(font => font.label === element.systemPlanStyle?.fontFamily)?.class : ""}
                        `}
                    style={{
                        fontFamily: element.systemPlanStyle?.fontFamily,
                        fontWeight: element.systemPlanStyle?.fontWeight ? element.systemPlanStyle?.fontWeight : "normal",
                        fontSize: getScaleSize(element?.systemPlanStyle?.fontSize || defaultFontSize, zoom || 0, 2)
                    }}
                >
                    {element.systemPlanStyle?.label || logicalAddress}
                </p>
            </Tooltip>
        </Marker>
    </>;
};

export default Element;