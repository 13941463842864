import Icon, { IconProps } from "@ews/react-icons";

import
{
    AuthorizationLevel
} from "@ews/zlt-events";

import
{
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";

import
{
    Polygon
} from 'geojson';

import
{
    StatusColor
} from "./Icons";

import
{
    useIonRouter
} from "@ionic/react";

import
{
    ReactDataClientError,
    useWebsocket
} from "./ReactData/ReactDataProvider";

import
{
    useMyPermissions,
    useMyProfileId
} from "./Login/LoginProvider";

import
{
    useSystemDetails,
    useSystemPermissions,
    useSystemRTM,
    useSystemShareOperate
} from "./ReactData/system";

import
{
    systemInAuthorization,
    systemInReactSession
} from "@ews/zlt-operate";

import
{
    AuthorizeResponse,
    permissionEmpty
} from "@ews/react-data";

import
{
    locationInArea,
    useGeoLocation
} from "./GeoLocation/GeoLocation";

import
{
    useWLanSSID
} from "./WLanSSID/WLanSSID";

type AcquireAuthorizationContextType = (level: AuthorizationLevel, code?: string) => Promise<boolean | any>;

export type AuthorizationContextType = {
    authorize: AcquireAuthorizationContextType,
    authorizationLevel: AuthorizationLevel;
};

const defaultAuthorizationContext: AuthorizationContextType = {
    authorize: async () => false,
    authorizationLevel: AuthorizationLevel.LEVEL1
};
const AuthorizationContext = createContext<AuthorizationContextType>(defaultAuthorizationContext);

export const useAuthorization = () =>
{
    return useContext(AuthorizationContext);
};

// const mockCodes: string[] = [
//     "0000", // unused
//     "1111",
//     "00000"
// ];

type AuthorizationStatusProps = Omit<IconProps, 'icon'>;

export const AuthorizationStatus: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const { authorizationLevel } = useAuthorization();

    style = { ...style, ...{ "--fill-override": StatusColor.ONLINE } as React.CSSProperties };

    switch (authorizationLevel) {
        case AuthorizationLevel.LEVEL1:
            return <Icon icon="lock_icon_locked" {...props} style={style} />;
        case AuthorizationLevel.LEVEL2:
            return <Icon icon="lock_icon_unlocked" {...props} style={style} />;
        case AuthorizationLevel.LEVEL3:
            return <Icon icon="lock_icon_unlocked2" {...props} style={style} />;
    }
};

export type AuthorizationProps = PropsWithChildren;

const permissionNotRequired = {};
const useAuthorizationPermitBySystemAuthorization = (systemId: string) =>
{
    const me = useMyProfileId();
    //const systemPermissions = useSystemPermissions(systemId);
    const systemPermissions = useSystemShareOperate(systemId, me);
    const rtm = useSystemRTM(systemId);

    if ("restrictOperationToAuthorizedUsers" in systemPermissions) {
        if (systemPermissions.restrictOperationToAuthorizedUsers) return systemInAuthorization(rtm.map(r => r.eventKindType));
        return permissionNotRequired;
    } else {
        return false;
    }
};
export const AuthorizationSystemAuthorization: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const systemId = useCurrentSystemId();
    const authorized = useAuthorizationPermitBySystemAuthorization(systemId);

    if (authorized === permissionNotRequired) return <></>;
    style = { ...style, ...{ "--fill-override": authorized ? StatusColor.ONLINE : StatusColor.OFFLINE } as React.CSSProperties };

    return <Icon icon="bmz_body_lck_aktiv" style={style} {...props} />;
};


const useAuthorizationPermitByWLanSSID = (systemId: string) =>
{
    const me = useMyProfileId();
    const systemPermissions = useSystemPermissions(systemId);
    const systemSharePermissions = useSystemShareOperate(systemId, me);

    const SSID = useWLanSSID(systemPermissions.restrictOperationToWlanSSID ? 5000 : 0);

    if ("restrictOperationToWlanSSID" in systemSharePermissions && "WlanSSID" in systemPermissions) {
        if (systemSharePermissions.restrictOperationToWlanSSID && systemPermissions.WlanSSID) {
            return SSID ? systemPermissions.WlanSSID === SSID : false;
        } else {
            return permissionNotRequired;
        }
    } else {
        return false;
    }
};

export const AuthorizationWLanSSID: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const systemId = useCurrentSystemId();
    const authorized = useAuthorizationPermitByWLanSSID(systemId);

    if (authorized === permissionNotRequired) return <></>;
    style = { ...style, ...{ "--fill-override": authorized ? StatusColor.ONLINE : StatusColor.OFFLINE } as React.CSSProperties };

    return <Icon icon="wlan" style={style} {...props} />;
};

const useAuthorizationPermitByGeoCoordinate = (systemId: string): boolean | {} =>
{
    const me = useMyProfileId();
    //const systemPermissions = useSystemPermissions(systemId);
    const systemPermissions = useSystemShareOperate(systemId, me);
    const systemDetails = useSystemDetails(systemId);
    const geoLocation = useGeoLocation(systemPermissions.restrictOperationToGPSArea ? 5000 : 0);

    if ("restrictOperationToGPSArea" in systemPermissions && "GPSArea" in systemDetails) {
        if (systemPermissions.restrictOperationToGPSArea && systemDetails.GPSArea) {
            return geoLocation ? locationInArea(geoLocation, systemDetails.GPSArea as Polygon) : false;
        } else {
            return permissionNotRequired;
        }
    } else {
        return false;
    }
};

const useAuthorizationPermitByGeoCoordinateBuffered = (systemId: string) =>
{
    const systemPermissions = useSystemPermissions(systemId);
    const permitted = useAuthorizationPermitByGeoCoordinate(systemId);
    const [buffered, setBuffered] = useState(permissionNotRequired);

    const bufferTimer = useRef<number>(0);

    const clearTimeout = () =>
    {
        window.clearTimeout(bufferTimer.current);
        bufferTimer.current = 0;
    };

    useEffect(() =>
    {
        if (!permitted && buffered === true) {

            if (!bufferTimer.current) {

                bufferTimer.current = window.setTimeout(() =>
                {
                    clearTimeout();
                    setBuffered(permitted);
                }, (systemPermissions.bufferGPSInAreaTime || 0) * 60 * 1000);
            }

        } else {

            if (bufferTimer.current) {

                clearTimeout();
            }
            setBuffered(permitted);
        }

    }, [permitted]);

    return buffered;
};

export const AuthorizationGeoCoordinate: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const systemId = useCurrentSystemId();
    const authorized = useAuthorizationPermitByGeoCoordinateBuffered(systemId);

    if (authorized === permissionNotRequired) return <></>;
    style = { ...style, ...{ "--fill-override": authorized ? StatusColor.ONLINE : StatusColor.OFFLINE } as React.CSSProperties };

    return <Icon icon="location" style={style} {...props} />;
};


const useAuthorizationPermitByReactSession = (systemId: string) =>
{
    const userPermissions = useMyPermissions();
    const me = useMyProfileId();
    //const systemPermissions = useSystemPermissions(systemId);
    const systemPermissions = useSystemShareOperate(systemId, me);
    const rtm = useSystemRTM(systemId);

    if ("restrictOperationToApprovedSession" in userPermissions && "restrictOperationToApprovedSession" in systemPermissions) {
        if (
            permissionEmpty(userPermissions.restrictOperationToApprovedSession!) &&
            !systemPermissions.restrictOperationToApprovedSession
        ) return permissionNotRequired;

        return systemInReactSession(rtm.map(r => r.eventKindType));
    } else {
        return false;
    }
};

export const AuthorizationReactSession: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{
    const systemId = useCurrentSystemId();
    const authorized = useAuthorizationPermitByReactSession(systemId);

    if (authorized === permissionNotRequired) return <></>;
    style = { ...style, ...{ "--fill-override": authorized ? StatusColor.ONLINE : StatusColor.OFFLINE } as React.CSSProperties };

    return <Icon icon="user" style={style} {...props} />;
};

export const usePermitAuthorization = (systemId: string) =>
{
    const reactSession = useAuthorizationPermitByReactSession(systemId);
    const systemAuthorized = useAuthorizationPermitBySystemAuthorization(systemId);
    const gpsAuthorized = useAuthorizationPermitByGeoCoordinateBuffered(systemId);
    const wLanAuthorized = useAuthorizationPermitByWLanSSID(systemId);

    return reactSession && systemAuthorized && gpsAuthorized && wLanAuthorized;
};

export const AuthorizationPermission: FC<AuthorizationStatusProps> = ({
    style = {},
    ...props
}) =>
{

    const systemId = useCurrentSystemId();

    const permissions = [
        useAuthorizationPermitByReactSession(systemId),
        useAuthorizationPermitBySystemAuthorization(systemId),
        useAuthorizationPermitByGeoCoordinateBuffered(systemId),
        useAuthorizationPermitByWLanSSID(systemId)
    ];

    const required = permissions.filter(p => p !== permissionNotRequired);
    if (!required.length) return <></>;

    const permitted = required.filter(p => p).length === required.length;

    style = { ...style, ...{ "--fill-override": permitted ? StatusColor.ONLINE : StatusColor.OFFLINE } as React.CSSProperties };
    const icon = permitted ? "key_icon_access_granted" : "key_icon_no_access";

    return <Icon icon={icon} style={style} {...props} />;

};

export const useCurrentSystemId = () =>
{
    const router = useIonRouter();
    const route = router.routeInfo.pathname.match(/^\/(?<locale>[^/]+)\/system\/(?<systemId>[^/]+).*/);
    return route?.groups?.systemId || "";
};

export const Authorization: FC<AuthorizationProps> = ({
    children
}) =>
{
    const systemId = useCurrentSystemId();
    const authorizationPermitted = usePermitAuthorization(systemId);
    const [authorizationLevel, setAuthorizationLevel] = useState(AuthorizationLevel.LEVEL1);
    const [authorizedSystem, setAuthorizedSystem] = useState(systemId);

    useEffect(() =>
    {
        if (!authorizationPermitted) {
            setAuthorizationLevel(AuthorizationLevel.LEVEL1);
        }
    }, [authorizationPermitted]);

    if (authorizedSystem !== systemId) {
        setAuthorizedSystem(systemId);
        setAuthorizationLevel(AuthorizationLevel.LEVEL1);
    }

    const authClient = useWebsocket();

    useEffect(() =>
    {
        const unregisterAuthorization = () =>
        {
            //@ts-ignore
            authClient.auth({
                type: "authorize",
            });
        };

        if (authorizationLevel === AuthorizationLevel.LEVEL1) unregisterAuthorization();

        window.addEventListener("beforeunload", unregisterAuthorization);
        return () =>
        {
            window.removeEventListener("beforeunload", unregisterAuthorization);
        };
    });

    const authorize: AcquireAuthorizationContextType = async (level: AuthorizationLevel, code?: string) =>
    {

        if (level === AuthorizationLevel.LEVEL1) {

            setAuthorizationLevel(level);
            return true;
        }

        if (!code) return false;

        try {

            const response = await authClient.auth({
                type: "authorize",
                systemId,
                level,
                code
            });

            if (!('success' in response)) return false;

            const data = response.success as AuthorizeResponse;

            if (!("level" in data)) return false;

            if (data.level !== level) {
                return data.currentAuthorization || false;
            }

            setAuthorizationLevel(data.level);
            return true;

        } catch (e) {
            const error = typeof e === "string" ? (JSON.parse(e as string) as ReactDataClientError).body.error : (e as Error).message;;
            setAuthorizationLevel(AuthorizationLevel.LEVEL1);
            throw error;
        }


    };

    const authorizationContext = { authorize, authorizationLevel };
    return (
        <AuthorizationContext.Provider value={authorizationContext}>
            {children}
        </AuthorizationContext.Provider>

    );
};