import
{
    useL10n
} from "@ews/react-localization-context";
import { IonText } from "@ionic/react";
import { flower } from "ionicons/icons";

import
{
    FC,
    useEffect,
    useState
} from "react";

export type ValidityCountDownProps = {
    validFrom: Date,
    validFor: number;
};

const formatPart = (n: number) =>
{
    return `${Math.floor(n)}`.padStart(2, '0');
};

const ValidityCountDown: FC<ValidityCountDownProps> = ({
    validFrom,
    validFor
}) =>
{

    const { translate: t } = useL10n();
    const [timeLeft, setTimeLeft] = useState(-1);

    useEffect(() =>
    {
        let timeout: number;

        const setTimer = () =>
        {

            const validTo = new Date(validFrom.getTime() + (validFor * 60 * 60 * 1000));
            const timeLeft = Math.floor((validTo.getTime() - Date.now()) / 1000);

            setTimeLeft(timeLeft);

            if (timeLeft > 0) timeout = window.setTimeout(() =>
            {
                setTimeLeft(prev => prev - 1);
            }, 1000);

        };

        setTimer();

        return () => clearTimeout(timeout);
    });

    if (timeLeft < 0) return <IonText style={{ float: "right" }}>{t('expired')}</IonText>;

    return <IonText style={{ float: "right" }}>{formatPart(timeLeft / 3600)}:{formatPart((timeLeft / 60) % 60)}:{formatPart(timeLeft % 60)}</IonText>;

};

export default ValidityCountDown;