import { useL10n } from "@ews/react-localization-context";
import { SystemPlan } from "@ews/react-data";
import { IonButton, IonHeader, IonItem, IonList, IonModal, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { useRef, useState } from "react";
import { useCreateSystemPlan, useSystemPlans } from "../../../ReactData/system";
import { useIsMobile } from "../../../Responsive";
import SettingsDropFile from "../../../Settings/SettingsComponents/SettingsDropFile/SettingsDropFile";
import SettingsNumberInput from "../../../Settings/SettingsComponents/SettingsNumberInput/SettingsNumberInput";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import modalStyle from "./NewPlanPage.module.scss";
import { Errors, NewPlanPageProps } from "./types";

const debounce = 500;

const NewPlanPageModal: React.FC<NewPlanPageProps> = ({ isOpen, systemId, list, onDismiss }) =>
{

    const plans = useSystemPlans(systemId);

    const mobileView = useIsMobile();

    const { translate: t } = useL10n();
    const modal = useRef<HTMLIonModalElement>(null);
    const [name, setName] = useState<string | undefined>(undefined);
    const [floor, setFloor] = useState<number | undefined>(undefined);
    const [image, setImage] = useState<string | undefined>(undefined);
    const [error, setError] = useState<Errors>({});
    const pageIdExistState = useRef<null | boolean>(null);

    const createPlan = useCreateSystemPlan();

    const disableBtn = Boolean(!(!Object.keys(error).length && name && pageIdExistState));

    async function confirm(label: SystemPlan["label"], floor: SystemPlan["floor"], image: SystemPlan["image"])
    {
        if (!label || !image) return;

        const newPage = {
            label,
            floor,
            image
        };
        createPlan(systemId, newPage as any);

        modal.current?.dismiss();
    }

    function onWillDismiss()
    {
        setName(undefined);
        setFloor(0);
        setError({});
        onDismiss(false);
    }
    function pageIdExist(floor: number)
    {
        const foundedPages = plans.filter(plan => plan.floor === floor);
        return Boolean(foundedPages.length);
    }

    function nameExist(name: string)
    {
        const foundedNames = plans.filter(plan => plan.label === name);
        return Boolean(foundedNames.length);
    }

    return <IonModal
        ref={modal}
        className={modalStyle.newSystemModal}
        isOpen={isOpen}
        //trigger="newPlanPage"
        breakpoints={mobileView ? [.6] : undefined}
        onWillDismiss={onWillDismiss}>
        <IonHeader>
            <IonToolbar>
                <div className={modalStyle.header}>
                    <IonTitle>{`${t("new Plan page")}`}</IonTitle>
                </div>
            </IonToolbar>
        </IonHeader>
        <IonList
            className="ion-padding"
            color="primary">
            <SettingsTextInput
                text={"Name"}
                debounce={debounce}
                value={name}
                onChange={(value) =>
                {
                    if (!value.length) {

                        setError({ name: t("please type in a name") });
                    }
                    else if (nameExist(value)) {
                        setError({ name: t("please choose a other name") });
                    } else {
                        delete error.name;
                        setName(value);
                    }
                }} />
            <SettingsNumberInput
                text={"Floor"}
                debounce={debounce}
                value={
                    floor ?
                        floor :
                        plans.length ? Math.max(...plans.map(plan => plan.floor || 0)) + 1 : 0}
                onChange={(value) =>
                {
                    const page = Number(value);

                    if (pageIdExist(page)) {
                        setError({ page: t("already exist") });
                    } else {
                        delete error.page;
                        setFloor(page);
                    }
                }} />
            <SettingsDropFile
                text={"Plan image"}
                accept="image/*"
                maxSize={8_000_000}
                info="Maximum upload size:"
                //onError={(error) => console.log(error)}
                onFile={function (file: File): void
                {
                    try {
                        const reader = new FileReader();
                        reader.onload = (e) =>
                        {
                            const base64String = e.target?.result;
                            if (base64String) {
                                delete error.image;
                                setImage(base64String.toString());
                            }
                        };
                        reader.readAsDataURL(file);
                    }
                    catch (err) {
                        setError({ image: t("can not upload the File") });
                    }
                }}
            />
            <IonItem lines={error ? "full" : "none"}>
                <div className={modalStyle.error}>
                    <IonText color={"danger"}>{Object.values(error).map(value => value).join(" ,")}</IonText>
                </div>
            </IonItem>
        </IonList>
        <div className={`${modalStyle.buttonContainer} ${modalStyle.marginBottom}`}>
            <IonButton
                slot="start"
                color={"danger"}
                onClick={() => modal.current?.dismiss()}>{t("Cancel")}</IonButton>
            <IonButton
                disabled={disableBtn}
                slot="end"
                color={"success"}
                onClick={() => confirm(name, floor, image)}>
                {t("Create")}
            </IonButton>
        </div>
    </IonModal>;

};


export default NewPlanPageModal;