import { useIonAlert } from "@ionic/react";

type MessageProps = {
    title: string,
    message: string;
};

export type AlertProps = MessageProps & {
    buttonTitle: string;
};

export type DialogProps = MessageProps & {
    confirmButtonTitle: string,
    cancelButtonTitle: string;
};

const useAlert = () =>
{
    const [present] = useIonAlert();
    return ({ title, message, buttonTitle = 'OK' }: AlertProps) =>
    {
        return new Promise((resolve) =>
        {
            present({
                header: title,
                message,
                buttons: [{
                    text: buttonTitle,
                    role: 'confirm',
                    handler: () => resolve(true)
                }]
            });
        });
    };
};

const useDialog = () =>
{
    const [present] = useIonAlert();
    return ({ title, message, confirmButtonTitle = 'OK', cancelButtonTitle = 'Cancel' }: DialogProps) =>
    {
        return new Promise((resolve) =>
        {
            present({
                header: title,
                message,
                buttons: [{
                    text: confirmButtonTitle,
                    role: 'confirm',
                    handler: () => resolve(true)
                },
                {
                    text: cancelButtonTitle,
                    role: 'cancel',
                    handler: () => resolve(false)
                }]
            });
        });
    };
};

export { useAlert, useDialog };