
import { useL10n } from "@ews/react-localization-context";
import { IonItem, IonLabel, IonText } from "@ionic/react";
import globalStyle from '../GlobalSettings.module.scss';
import style from './SettingsLabel.module.scss';
import { SettingsLabelProps } from "./types";
import { useIsMobile } from "../../../Responsive";

const SettingsLabel: React.FC<SettingsLabelProps> = ({
    children,
    hidden = false,
    text = '',
    tooltip,
    value,
    labelPosition = 'stacked',
    lines = undefined,
}) =>
{
    const { translate: t } = useL10n();
    const mobileView = useIsMobile();




    return <IonItem hidden={hidden} lines={lines} className={`${style.overflow} ${globalStyle.setting}`}>
        <span className={`${style.flex} ${style.justifyStart} ${style.fullWidth}`}>
            <IonLabel style={{ whiteSpace: "break-spaces" }} className={style.labelWidth} position={labelPosition}>{`${t(text)}`}</IonLabel>
            <span className={style.tooltip}>
                {mobileView ? <></> : tooltip}
            </span>
        </span>

        {value && <div className={style.textContainer}>
            <IonText className={`${style.textPosition}  ${!mobileView ? style.allowSelect : ""}`}>{value}</IonText>
        </div>}
        {children && <div className={`${style.textContainer}`}>
            {children}
        </div>
        }
    </IonItem >;
};

export default SettingsLabel;