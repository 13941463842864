import { useL10n } from "@ews/react-localization-context";
import { permissionAllowed, permissionAvailable, User, UserDetails } from "@ews/react-data";
import { IonButton } from "@ionic/react";
import { useRef } from "react";
import { useModifyUser, useModifyUserDetails, useUser, useUserDetails, useUserPermissions } from "../../../ReactData/user";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { MainSettingsProps } from "./types";

import CustomerTypeAhead from "../../../Customer/CustomerTypeAhead";
import { useCustomer } from "../../../ReactData/customer";
import { useMyProfileId } from "../../../Login/LoginProvider";
import { useDialog } from "../../../Dialog";

const MainSettings: React.FC<MainSettingsProps> = ({ id }) =>
{
    const { translate: t } = useL10n();
    const dialog = useDialog();

    const saveAsTemplate = useRef<boolean>(false);

    const user = useUser(id || "");
    const userDetails = useUserDetails(id || "");

    const me = useMyProfileId();
    const userPermission = useUserPermissions(me);

    const modifyUser = useModifyUser();
    const modifyUserDetails = useModifyUserDetails();

    // const customer = useCustomer(user.customerId || "");
    const updateUserDetails = async (data: UserDetails) =>
    {
        const modifiedUserData = await modifyUserDetails(user.id!, data);
        console.log(modifiedUserData);
    };

    const updateUser = async (data: User) =>
    {
        /* const modifiedUser = */ await modifyUser(user.id!, data);
        //console.log(modifiedUser);
    };

    return <>
        <SettingsCol>
            <SettingsCheckbox text={"Aktive"} checked={user.active || false} onChange={(active) => { updateUser({ active } as User); }} />

            <SettingsLabel text={"User-ID"} value={user?.username!} />

            <SettingsSelect text={"Salutation"} value={userDetails.salutation} onSelect={(salutation) => updateUserDetails({ salutation } as UserDetails)}>
                <SettingsSelectOption text={t("Salutation.m")} value={"m"} />
                <SettingsSelectOption text={t("Salutation.f")} value={"f"} />
                <SettingsSelectOption text={t("Salutation.c")} value={"c"} />
                <SettingsSelectOption text={t("Salutation.other")} value={"other"} />
            </SettingsSelect>

            <SettingsTextInput
                required={true}
                text={"First Name"}
                inputType={"text"}
                value={userDetails?.firstName}
                onChange={(firstName) => { updateUserDetails({ firstName } as UserDetails); }} />

            <SettingsTextInput
                required={true}
                text={"Last Name"}
                inputType={"text"}
                value={userDetails?.lastName}
                onChange={(lastName) => { updateUserDetails({ lastName } as UserDetails); }} />

            <SettingsTextInput
                required={true}
                text={"E-mail"}
                inputType={"text"}
                value={user?.email}
                onChange={(email) => { updateUser({ email } as User); }} />
            {/* <SettingsTextInput 
            text={"Landline"} inputType={"text"} value={MainDataRef.current.landline} 
            onChange={(value) => { MainDataRef.current.landline = value; }} /> */}

            <SettingsTextInput
                required={true}
                text={"Phone number"}
                inputType={"text"}
                value={userDetails.phoneNumber}
                onChange={(phoneNumber) => { updateUserDetails({ phoneNumber } as UserDetails); }}
            />

            <CustomerTypeAhead
                currentId={user.customerId || ""}
                disabled={!permissionAllowed(userPermission.isAdmin!)}
                onChange={(customerId => updateUser({ customerId } as User))}
            />

        </SettingsCol>
        <SettingsCol>
            <IonButton
                expand="full"
                onClick={async () =>
                {
                    const confirm = await dialog({
                        title: t('Reset password'),
                        message: t('User will receive Password reset Email.'),
                        confirmButtonTitle: t('Confirm'),
                        cancelButtonTitle: t('Cancel')
                    });
                    console.log(confirm);
                    //if (confirm) updateUser({ 'accessToken': null } as User);
                }
                }
            >{t('Reset password')}</IonButton>
            <SettingsCheckbox
                text={"Save as template"} checked={saveAsTemplate.current}
                onChange={(checked) => { saveAsTemplate.current = checked; }} />
        </SettingsCol>
        <SettingsCol>

        </SettingsCol>

    </>;
};

export default MainSettings;