import { useL10n } from "@ews/react-localization-context";

import
{
    buildOutline,
    callOutline,
    keyOutline,
    mapOutline,
    options,
    statsChartOutline,
    thermometerOutline,
    trashOutline
} from "ionicons/icons";

import
{
    FC
} from "react";

import
{
    MenuLink,
    MenuSpacer,
    Sidebar,
    SidebarSection
} from "./Sidebar";

import
{
    RouteParams,
    useNamedRoutes
} from "../../NamedRoutes";

import type {
    MenuToggleProps
} from "../types";

import
{
    AcquireAuthorization
} from "./Main";

import
{
    UserPermissions,
    permissionAllowed
} from "@ews/react-data";

import
{
    useMyPermissions
} from "../../Login/LoginProvider";
import { useAuthorization } from "../../Authorization";
import { AuthorizationLevel } from "@ews/zlt-events";
import { useAlert, useDialog } from "../../Dialog";
import { useRemoveSystem } from "../../ReactData/system";
import { ReactClickEvent } from "../../types";

type SystemSidebarProps = MenuToggleProps & {
    routeParams?: RouteParams;
    title?: string;
};

const System: FC<SystemSidebarProps> = ({
    routeParams = {},
    onMenuToggle,
    title
}) =>
{
    const { translate: t } = useL10n();
    const { generate, params, router } = useNamedRoutes();
    const permissions: UserPermissions = useMyPermissions();
    const { authorizationLevel } = useAuthorization();
    const removeSystem = useRemoveSystem();
    const dialog = useDialog();

    if (!("permitAccessLevel3" in permissions)) return <></>;

    let isAllowedMeasurements = authorizationLevel > AuthorizationLevel.LEVEL2;
    if (!isAllowedMeasurements) {
        if (params().id) {
            isAllowedMeasurements = Boolean(permissions.detectorValuesAccessLevel2 && (authorizationLevel > AuthorizationLevel.LEVEL1));
        } else {
            isAllowedMeasurements = permissionAllowed(permissions.permitAccessLevel3!);
        }
    }

    title = title || t("Systemsettings");

    const handleDelete = async (e: ReactClickEvent) =>
    {
        const systemId = routeParams.id || params().id;
        if (!systemId) return;

        const response = await dialog({
            title: t("Remove system"),
            message: t("Are ou sure, you want to completely remove the system from REACT?"),
            confirmButtonTitle: t("Ok"),
            cancelButtonTitle: t("Cancel")
        });

        if (response) {
            await removeSystem(systemId);
            onMenuToggle && onMenuToggle(e);
            const redirect = generate('systems');
            router?.push(redirect);
        }

    };

    return (
        <Sidebar title={title} onMenuToggle={onMenuToggle}>
            <SidebarSection grow>
                <AcquireAuthorization systemId={params().id || ""} onMenuToggle={onMenuToggle} />
                {permissionAllowed(permissions.grantSystemAccess!) ? <><MenuLink
                    title={t('[SID_MNU_TAB_SETTINGS]')}
                    onClick={onMenuToggle}
                    link={generate('system:settings', routeParams)}
                    icon={options}
                />
                    <MenuSpacer /></> : <></>}
                <MenuLink
                    title={t('Show Plans')}
                    onClick={onMenuToggle}
                    icon={mapOutline}
                />
                <MenuLink
                    title={t('Maintenance report')} //[SID_SUB_MNU_STA_SERVICE_LIST] ?
                    onClick={onMenuToggle}
                    link={generate('system:maintenancereport', routeParams)}
                    icon={buildOutline}
                />
                <MenuLink
                    title={t('Event storage')} // [SID_MNU_TAB_EVENT_MEM] ? 
                    onClick={onMenuToggle}
                    link={generate('system:eventmemory', routeParams)}
                    icon={statsChartOutline}
                />
                {isAllowedMeasurements && <MenuLink

                    title={t('Show measurement')}
                    onClick={onMenuToggle}
                    link={generate('system:analoguevalues', routeParams)}
                    icon={thermometerOutline}
                />}
                {/* <MenuLink
                    title={t('Show queries')}
                    onClick={onMenuToggle}
                    link={generate('system:queries', routeParams)}
                    icon={swapVerticalOutline}
                /> */}
                <MenuLink
                    title={t('Show shares')}
                    onClick={onMenuToggle}
                    link={generate('system:shares', routeParams)}
                    icon={keyOutline}
                />
                <MenuLink
                    title={t('Show phonenumbers')}
                    onClick={onMenuToggle}
                    link={generate('system:phonenumber', routeParams)}
                    icon={callOutline}
                />
                {/* <MenuSpacer />
                <MenuLink
                    title={t('Details on submission documents')}
                    link={generate('system:submissiondocuments', routeParams)}
                    onClick={onMenuToggle}
                    icon={documentTextOutline}
                />
                <MenuLink
                    title={t('Power packs for submission documents')}
                    link={generate('system:powerunitsDocumentation', routeParams)}
                    onClick={onMenuToggle}
                    icon={batteryChargingOutline}
                />
                <MenuLink
                    title={t('Show devices')}
                    link={generate('system:displaydevice', routeParams)}
                    onClick={onMenuToggle}
                    icon={desktopOutline}
                />
                <MenuLink
                    title={t('Other alarm devices')}
                    link={generate('system:otheralarmdevices', routeParams)}
                    onClick={onMenuToggle}
                    icon={megaphoneOutline}
                />
                <MenuLink
                    title={t('Other devices')}
                    link={generate('system:otherdevices', routeParams)}
                    onClick={onMenuToggle}
                    icon={terminalOutline}
                />
                <MenuLink
                    title={t('Inputs for submission documents')}
                    link={generate('system:inputssystemDocumentation', routeParams)}
                    onClick={onMenuToggle}
                    icon={enterOutline}
                />
                <MenuLink
                    title={t('Type definition submission documents')}
                    link={generate('system:typedefinitionsubmissiondocuments', routeParams)}
                    onClick={onMenuToggle}
                    icon={listOutline}
                />
                <MenuLink
                    title={t('Documentation history')}
                    link={generate('system:Documentationhistory', routeParams)}
                    onClick={onMenuToggle}
                    icon={serverOutline}
                /> */}
                {permissionAllowed(permissions.grantSystemAccess!) ? <><MenuSpacer />
                    <MenuLink
                        title={t('[SID_MNU_HK_DELETE]')}
                        onClick={handleDelete}
                        icon={trashOutline}
                    /></> : <></>}

            </SidebarSection>
        </Sidebar>
    );

};

export default System;