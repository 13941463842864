import { permissionAllowed, permissionAvailable, System, SystemDetails, SystemStatus } from "@ews/react-data";
import { useL10n } from "@ews/react-localization-context";

import
{
    useModifySystem,
    useModifySystemDetails,
    useSystem,
    useSystemDetails
} from "../../../ReactData/system";

import { Point, Polygon } from "geojson";
import CustomerTypeAhead from "../../../Customer/CustomerTypeAhead";
import { useMyProfileId } from "../../../Login/LoginProvider";
import { useCustomer } from "../../../ReactData/customer";
import { useWebsocket } from "../../../ReactData/ReactDataProvider";
import { useUserPermissions } from "../../../ReactData/user";
import SettingsCol from "../../../Settings/SettingsCol/SettingsCol";
import SettingsCheckbox from "../../../Settings/SettingsComponents/SettingsCeckbox/SettingsCeckbox";
import SettingsSelect from "../../../Settings/SettingsComponents/SettingsSelect/SettingsSelect";
import SettingsSelectOption from "../../../Settings/SettingsComponents/SettingsSelectOption/SettingsSelectOption";
import SettingsTextInput from "../../../Settings/SettingsComponents/SettingsTextInput/SettingsTextInput";
import { TooltipIcon } from "../../../ToolTip/useTooltip";
import GPSArea from "../../GPSArea/GPSArea";
import { MainSettingsProps } from "./types";
import SettingsLabel from "../../../Settings/SettingsComponents/SettingsLabel/SettingsLabel";


const MainSettings: React.FC<MainSettingsProps> = ({ systemId }) =>
{
    const system = useSystem(systemId);
    const details = useSystemDetails(systemId);

    const me = useMyProfileId();
    const userPermission = useUserPermissions(me);

    const modifySystem = useModifySystem();
    const modifySystemDetails = useModifySystemDetails();

    //const customer = useCustomer(system.customerId || "");
    const { translate: t } = useL10n();

    async function saveSystem(data: System)
    {
        await modifySystem(system.id!, data);
    }
    async function saveSystemDetails(data: SystemDetails)
    {
        await modifySystemDetails(system.id!, data);//
    }

    return <>
        <SettingsCol >
            <SettingsCheckbox
                text={"[SID_MNU_TAB_STATUS]"}
                checked={system.status === SystemStatus.ONLINE}
                onChange={(checked) =>
                {
                    const status = checked ? SystemStatus.ONLINE : SystemStatus.OFFLINE;
                    saveSystem({ status } as System);
                }} />
            {/*<SettingsLabel
                text="Assigned client"
                tooltip={<TooltipIcon text={t("der Assigned client der Anlage")} />}
                value={systemState.customerId || ''} /> */}
            <CustomerTypeAhead
                label={t('Assigned client')}
                currentId={system.customerId || ""}
                onChange={(customerId => saveSystem({ customerId: customerId || null } as System))}
                disabled={!permissionAllowed(userPermission.isAdmin!)}
            />
            {/*  <SettingsLabel
                text="TD_SERIENNR"
                tooltip={<TooltipIcon text={t("der Serial number der Anlage")} />}
                value={details.serialNumber || ''} />
                */}
            <SettingsLabel
                text={"System ID"}
                tooltip={<TooltipIcon text={t("der System-ID der Anlage")} />}
                value={`${system.systemNumber || ""}`} />
            <SettingsTextInput
                text={"System name"}
                tooltip={<TooltipIcon text={t("der Serial name der Anlage")} />}
                inputType={"text"}
                value={system.name || ''}
                onBlur={({ value }) =>
                {
                    if (system.name === value) return;
                    saveSystem({ "name": value } as System);
                }
                } />
            <SettingsTextInput
                text={"Location"}
                endText={' 1'}
                tooltip={<TooltipIcon text={t("der Location der Anlage")} />}
                inputType={"text"}
                value={details.location || ''}
                onBlur={({ value }) =>
                {
                    if (details.location === value) return;
                    saveSystemDetails({ "location": value } as SystemDetails);
                }} />
            <SettingsTextInput
                text={"Site"}
                tooltip={<TooltipIcon text={t("der Site number der Anlage")} />}
                inputType={"text"}
                value={details.site || ''}
                onBlur={({ value }) =>
                {
                    if (details.site === value) return;
                    saveSystemDetails({ "site": value } as SystemDetails);
                }} />
            <SettingsTextInput
                disabled
                text={"Address"}
                tooltip={<TooltipIcon text={t("der Address number der Anlage")} />}
                value={details.street || ''}
                onBlur={({ value }) =>
                {
                    if (details.street === value) return;
                    saveSystemDetails({ "street": value } as SystemDetails);
                }} />
            <SettingsTextInput
                disabled
                text={"Postal code"}
                tooltip={<TooltipIcon text={t("der Postal code der Anlage")} />}
                inputType={"text"}
                value={details.zip || ''}
                onBlur={({ value }) =>
                {
                    if (details.zip === value) return;
                    saveSystemDetails({ "zip": value } as SystemDetails);
                }} />
            <SettingsTextInput
                disabled
                text={"City"}
                tooltip={<TooltipIcon text={t("der City der Anlage")} />}
                inputType={"text"}
                value={details.city || ''}
                onBlur={({ value }) =>
                {
                    if (details.city === value) return;
                    saveSystemDetails({ "city": value } as SystemDetails);
                }
                } />
            <SettingsSelect
                disabled
                text={"Country"}
                tooltip={<TooltipIcon text={t("der Country der Anlage")} />}
                value={details.country || ''}
                onBlur={({ value }) =>
                {
                    if (details.country === value) return;
                    saveSystemDetails({ "country": value } as SystemDetails);
                }}>
                <SettingsSelectOption text={"Österreich"} value={"AT"} />
                <SettingsSelectOption text={"Deutschland"} value={"DE"} />
                <SettingsSelectOption text={"Schweiz"} value={"CH"} />
                <SettingsSelectOption text={"Kroatien"} value={"HR"} />
                <SettingsSelectOption text={"Ungarn"} value={"HU"} />
                <SettingsSelectOption text={"Bosnien"} value={"BA"} />
                <SettingsSelectOption text={"Frankreich"} value={"FR"} />
                <SettingsSelectOption text={"Niederlande"} value={"NL"} />
            </SettingsSelect>
        </SettingsCol>
        <SettingsCol lg={'6'} xl={'8'}>

            <SettingsCheckbox
                text={"Send location of system to devices"}
                tooltip={<TooltipIcon text={t("Send location of system to devices")} />}
                checked={details.exposeGPS || false}
                onChange={(exposeGPS) =>
                {
                    saveSystemDetails({ "exposeGPS": exposeGPS } as SystemDetails);
                }} />

            <GPSArea
                area={details.GPSArea as Polygon}
                position={details.GPSPosition as Point}
                onChange={(changes) => saveSystemDetails(changes as SystemDetails)}

            />
        </SettingsCol>
    </>;
};

export default MainSettings;